import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StaticFooter = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: #ffffff;
  padding: 2.1rem 4rem;
`;

export const StaticFooterSection = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

export const StaticFooterIcon = styled('div')``;

export const StaticFooterText = styled(Typography)`
  font-size: 2rem;
  font-weight: ${({ tag }) => (tag ? '600' : '400')};
  color: ${({ tag }) => (tag ? '#235A91' : '')};
`;
