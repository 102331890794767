import { styled } from '@mui/material/styles';
import {
  Button, Grid, TableCell, TableRow, Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  AddPlus, SubtractMinus,
} from '../../../assets/svgs';

const sm = '1371px';

export const IsHybrid = styled('div')`
  border-radius: 7px;
  text-transform: capitalize;
  font-size: .75rem;
  font-weight: 500;
  padding: 2px 3px;
  width: 4.2rem;
  margin-bottom: 3px;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  background: #E1FFE2;
  color: #07DE10;
`;

export const ProductContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 3rem 0;
  margin: 0 2rem;
  border-bottom: 2px solid #ECECEC;

  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;
    padding: 35px 0;
    margin: 0 10px;
    border-bottom: 1px solid #f0f0f0;
  }
`;

export const ProductDetail = styled(TableCell)`
  padding: 1rem 0;
  max-width: 300px !important;
  min-width: 300px !important;
`;

export const ProductDetailFlex = styled('div')`
  display: flex;
  align-items: center;
`;

export const ProductDetailImage = styled('img')`
  width: 14rem;
  max-height: 14rem;

  @media (min-width: 992px) {
  width: 95px;
  max-height: 70px;
  }
`;
export const ImageWrapper = styled(Grid)`
  width: 11rem;
  max-height: 11rem;
  border-radius: 10px;
  text-align: center;

  @media (min-width: 992px) {
  width: 100px;
  max-height: 100px;
  margin-top: 0rem;
  }
`;

export const ProductDetailBrandNameText = styled('p')`
  color: #424242;
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  text-transform: title-case;
  margin-bottom: 5px;
  text-overflow: ellipsis;

  @media (min-width: 992px) {
    line-height: 30px;
    font-size: 1rem;
  }
`;

export const OutOfStockTypo = styled('div')`
  color: #858383;
  font-size: 12px;
  font-weight: 600;
  line-height: 23px;
  border: 1px solid #E4E4E4;
  border-radius: 500px;
  padding: 0 .8rem;
  width: fit-content;

  @media (max-width: 991px) {
    font-size: 30px;
    line-height: 3.5rem;
    border: 1.5px solid #E4E4E4;
    padding: 0 1.5rem;
  }
`;

export const ProductDetailBrandTypo = styled(Typography)`
  font-size: 40px;
  font-weight: 600;
  text-transform: title-case;
  margin-bottom: 10px;

  @media (max-width: 991px) {
    font-size: 35px;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 5px;
  }
`;

export const ProductDetailPackSizeText = styled('p')`
  margin: 0;
  color: #606060;
  font-size: .875rem;
  line-height: 23px;
  font-weight: 500;

  @media (max-width: 991px) {
    color: #606060;
    margin-right: 20px;
    font-size: 30px;
    font-weight: 500;
  }
`;

export const ProductDetailSavingsText = styled('p')`
  margin: 0;
  color: #3cc480;
  font-weight: 600;
  font-size: 13px;

  @media (max-width: 991px) {
    font-size: 24px;
    position: absolute;
    top: 8rem;
    left: 12.8rem;
  }
`;

export const PromoText = styled('p')`
  margin: 0;
  color: #3cc480;
  font-weight: 600;
  font-size: 13px;

  @media (max-width: 991px) {
    font-size: 24px;
    margin-top: 5px;
  }
`;

export const ProductDetailItemPriceText = styled('p')`
  margin: 0;
  margin-right: auto;
  line-height: 23px;
  font-weight: 600;
  font-size: 16px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};

  @media (max-width: 991px) {
    font-size: 35px;
    // margin-top: 5.2rem;
    margin-bottom: 15px
  }
`;

export const HideMd = styled('div')`
  display: flex;
  align-items: center;
  flex: 1;

  @media (max-width: 991px) {
    display: none;
  }
`;

export const ShowMd = styled(Grid)`
  position: relative;
  min-height: 24rem;
  border-bottom: 2px solid #E7E8E9;
  padding: 2rem;
  margin-top: 2rem;
  border-radius: 15px;

  @media (min-width: 992px) {
    display: none;
  }
`;

export const GreySpan = styled('span')`
    color: #A3A3A3;
    cursor: pointer;
`;

export const ProductCounter = styled('div')`
  display: flex;
  align-items: center;
`;

export const WarningText = styled(Grid)`
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  color: #E74C3C;
  justify-content: center;

  @media (max-width: 991px) {
  font-size: 25px;
  margin-top: 15px;
  }
`;

export const ProductCounterWrapper = styled('div')`

`;

export const OutOfStock = styled('p')`
  font-size: 15px;
  color: red;
  font-weight: 600;
  margin: 0;
  @media (max-width: 991px) {
    font-size: 27px;
    padding-left: 17rem;
  }
`;

export const ValuesGrid = styled(Grid)`
  align-items: center;
  font-size: 1.5rem;
  margin-top: 1rem;
`;

export const ProductCounterStatText = styled('p')`
  display: flex;
  width: 96px;
  height: 60px;
  font-size: 18px;
  line-height: 17px;
  color: #424242;
  background-color: #f0f0f0;
  justify-content: center;
  align-items: center;
  padding: 0;

  @media (max-width: ${sm}) {
    width: 70px;
    height: 35px;
    font-size: 13px;
    line-height: 13px;
  }
`;

export const ProductCounterQuantityInput = styled('input')`
  width: 60px;
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  line-height: 17px;
  color: #424242;
  outline: none;
  border: none;
  text-align: center;

  @media (max-width: 991px) {
    width: 120px;
    font-size: 2.5rem;
  }

  &:disabled {
    opacity: 0.3;
  }
`;

export const ProductCounterButton = styled(Button)`
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #ECF6FF;
  border-radius: 7.5px;
  &.MuiButton-root {
    min-width: 0px;
  }
  @media (max-width: 991px) {
    width: 85px;
    height: 85px;
  }
`;

export const ProductCounterRemoveButton = styled(Button)`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid #FAA9A3;
  border-radius: 50%;
  // border-top-left-radius: 5px;
  // border-bottom-left-radius: 5px;
  cursor: pointer;
  &.MuiButton-root {
    min-width: 0px;
  }
  @media (max-width: 991px) {
    width: 50px;
    height: 50px;
  }
`;

export const ProductCounterAddButton = styled(Button)`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid #A9D3AB;
  border-radius: 50%;
  // border-top-right-radius: 5px;
  // border-bottom-right-radius: 5px;
  cursor: pointer;
  &.MuiButton-root {
    min-width: 0px;
  }
  @media (max-width: 991px) {
    width: 50px;
    height: 50px;
  }

`;

export const ProductCounterMobileContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobileCounterGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 14rem;
  left: 13rem;
  width: 36rem;
`;

export const ProductDetailMainContainer = styled('div')`
  width: 145px;
  margin-right: 3rem;

  @media (min-width: 2560px) {
    margin-right: 12%;
  }
`;

export const ProductDetailPriceMobileContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const ProductDetailNameMainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1.5rem;
`;

export const Remove = styled(RemoveIcon)`
  font-size: 3rem;
  color:  #FAA9A3;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const Add = styled(AddIcon)`
  font-size: 3rem;
  color: #A9D3AB;
  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const Delete = styled('img')`
  font-size: 3.5rem;
  height: 3rem;
  color: #A3A3A3;

  @media(min-width: 992px) {
    font-size: 1.5rem;
    height: 1.5rem;
  }
`;

export const EditImg = styled('img')`

`;

export const TickImg = styled('img')`
  width: 7%;
  cursor: pointer;

  @media(max-width: 991px) {
    position: absolute;
    top: 4rem;
    left: -15px;
  }
`;

export const MinQtyTypo = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 400;
  color: #424242;
  @media(min-width: 992px) {
    font-size: .9rem;
  }
`;

export const ProductVariantText = styled('p')`
  font-size: 1.3rem;
  text-align: right;
  margin: 0;
  padding: 0;
  color: #424242;
  padding: 0 1.5%;
  position: absolute;
  left: 12px;
  top: -15px;
  border-radius: 5px;
  background-color: #FCE850;
  @media(min-width: 992px) {
    font-size: .9rem;
    top: -15px;
  }
`;

export const MainTableRow = styled(TableRow)`
  border-top: 1px solid #E7E8E9;
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  padding: 1.6rem 10px;
  @media (min-width: 992px) {
    font-weight: 600;
    font-size: 17px;
  }
`;

export const ShopNowButton = styled(Button)`
  background-color: #235A91;
  border-radius: 5px;
  color: #fff;

  padding: 11px 3rem;
  margin-top: 25px;

  // @media(max-width: 991px) {
  //   font-size: ${(props) => (props.cartIndex ? '2.1rem' : '2rem')};
  //   font-weight: ${(props) => (props.cartIndex && '700')};
  //   height: ${(props) => (props.cartIndex && '120px')};
  //   width: ${(props) => (props.cartIndex && '25rem')};
  //   margin-top: ${(props) => (props.cartIndex && '25px')};
  // }

  &:hover {
    background-color: #235A91;
  }

  @media (max-width: 991px) {
    font-size: 1.7rem;
    padding: 14px 3rem;

  }
`;

export const BulkUploadButton = styled(Button)`
  border: 1px solid #235A91;
  border-radius: 8px;
  color: #235A91;
  padding: 11px 3rem;
  margin-top: 25px;
  margin-left: 15px;

   @media (max-width: 991px) {
    font-size: 1.8rem;
  }
`;

export const MobileText = styled(Typography)`
  margin: 1rem 0 .5rem 0;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 500;
`;

export const MobileButttonGrid = styled(Grid)`
  align-items: center;
  gap: 3rem;
  margin-top: 2rem;
  padding-left: 0.7rem;
  margin-right: 4rem;
`;

export const RemoveText = styled(Typography)`
  font-size: 1.7rem;
  padding: 0px 10px;

  @media (max-width: 991px) {
   font-size: 2.1rem;
   font-weight: 400;
  }
`;

export const DotsButton = styled(Typography)`
  font-weight: 700;
  padding-bottom: .7rem;
  font-size: 1.6rem;
  cursor: pointer;
`;

export const AddPlusImg = styled(AddPlus)`
  &.MuiSvgIcon-root {
    width: 19px;
    height: 19px;
    color: ${({ disabled }) => (disabled ? '#D7D7D7' : '#37833B')};
  }

  @media (max-width: 991px) {
    &.MuiSvgIcon-root {
      width: 55px;
      height: 55px;
    }
  }
`;

export const SubtractMinusImg = styled(SubtractMinus)`
  &.MuiSvgIcon-root {
    width: 24px;
    height: 24px;
    color: ${({ disabled }) => (disabled ? '#D7D7D7' : '#C8372D')};
  }

  @media (max-width: 991px) {
    &.MuiSvgIcon-root {
      width: 65px;
      height: 65px;
    }
  }
`;

export const TableValueText = styled(Typography)`
  font-weight: 600;
  font-size: 1rem;
  color: #212121;
`;
