import { useMutation } from '@apollo/client';
import {
  Box,
  Dialog,
  Grid,
  Slide,
  useMediaQuery
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import toast from 'react-hot-toast';
import { ADD_SAVE_CART_TO_CART } from '../../mutations/cart';
import { CloseIcon } from '../shared/uploadProduct/individual/individualProductDialog.styles';
import {
  CDialog,
  DialogText,
  GridItem,
  GridTitle,
} from './renderPopper.styles';
import { RestoreCartBasketIcon, RestoreCartIcon } from '../../assets/svgs';
import { parseJson } from '../affiliates/utils';
import { SAVED_CART } from '../../queries/cart';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const SavedCartDialog = ({
  savedCartDialog,
  handleClose,
  cartOrder,
  refetch
}) => {
  const [restoreSaveOrderCart, { loading }] = useMutation(ADD_SAVE_CART_TO_CART);

  const handleAddCartOrder = (id) => {
    restoreSaveOrderCart({ variables: { savedCartId: Number(id) }, refetchQueries: [SAVED_CART] })
      .then(({ data }) => {
        const { message } = data?.restoreSavedCart || {};
        toast.success(message);
        refetch();
        handleClose();
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };

  const isSmall = useMediaQuery('(max-width: 991px)');

  return (
    <Dialog
      open={savedCartDialog}
      ransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: 15,
          overflowY: 'scroll',
          width: isSmall ? '950px' : '600px',
          padding: '2.3rem'
        }
      }}
    >
      <Grid container>
        <Grid item container xs={6}>
          <CDialog>
            Saved Cart
          </CDialog>
          <Grid item container xs={6} justifyContent="flex-end">
            <CloseIcon
              onClick={handleClose}
              style={{
                position: 'absolute',
                right: '1.5rem',
                top: '2rem'
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <GridTitle>
        <DialogText
          style={{
            color: '#606060',
            fontWeight: '400',
            margin: isSmall && '2rem 0'
          }}
        >
          A quick identifier when you decide to continue with this cart.
        </DialogText>
      </GridTitle>
      {cartOrder.map((item) => {
        const { id, meta } = item;
        const { comment } = parseJson(meta);
        const reformatComment = comment?.length > 50 ? `${comment.slice(0, 50)}...` : comment;
        return (
          <GridItem key={id} style={{ marginBottom: isSmall && '2rem' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
              <RestoreCartBasketIcon style={{ fontSize: '60px' }} />
              <DialogText>{reformatComment}</DialogText>
            </Box>
            <Box
              style={{
                cursor: 'pointer',
                pointerEvents: loading ? 'none' : ''
              }}
            >
              <RestoreCartIcon style={{ fontSize: isSmall && '3rem' }} onClick={() => handleAddCartOrder(id)} />
            </Box>
          </GridItem>
        );
      })}
    </Dialog>
  );
};

SavedCartDialog.propTypes = {
  savedCartDialog: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  cartOrder: PropTypes.arrayOf(Array).isRequired,
  refetch: PropTypes.func.isRequired,
};

export default SavedCartDialog;
