import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import {
  Grid, Box, TableContainer, Table, TableBody
} from '@mui/material';
import toast from 'react-hot-toast';
import { UPDATE_PRODUCTS_STATUS_MUTATION } from '../../../mutations/products';
import {
  HeaderWrapper, ContactGrid, TableRowStyled, TableCellBodyStyled, ContainerFlex, StatusColor, StatusText,
  SmallHeading, NormalText2, ImgContainer, CardTitle, TableHeadStyled, TableCellStyled, TableCellRow, MoleculeText
} from './productInfo.style';
import ActionPopper from './actionPopper';
import { JSONParse } from '../../../utils/json';

const ProductInfo = ({ info, refetch }) => {
  const {
    id, brandName, dateCreated, status, quantityInStock, sellingPrice, molecule, meta, sku
  } = info;

  const obj = JSON.parse(meta);
  const { pack_size: packSize, description, image } = obj;
  const [updateProductStatus] = useMutation(UPDATE_PRODUCTS_STATUS_MUTATION);
  const updateProductStatusHandler = () => {
    updateProductStatus({
      variables: {
        productId: [id],
        status: status === 'ACTIVE' ? 'IN_ACTIVE' : 'ACTIVE'
      }
    })
      .then(({ data }) => {
        const { message } = data?.updateProductStatus || {};
        toast.success(message);
        refetch();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  // eslint-disable-next-line consistent-return
  const getStatus = () => {
    if (status === 'ACTIVE') {
      return (
        <Box style={{ display: 'flex' }}>
          <StatusColor active />
          <StatusText>Enabled</StatusText>
        </Box>
      );
    }
    return (
      <Box style={{ display: 'flex' }}>
        <StatusColor />
        <StatusText>Disabled</StatusText>
      </Box>
    );
  };

  const handleAction = (setOpen) => {
    updateProductStatusHandler();
    setOpen(false);
  };

  const datePlaced = moment(dateCreated).format('DD/MM/YYYY');
  const moleculeStr = molecule ? JSONParse(molecule).toString().replaceAll(',', ', ') : '';

  return (
    <HeaderWrapper container spacing={6}>
      <ContactGrid item xs={12} md={12}>
        <Box p={4} bgcolor="background.paper" borderRadius={4}>
          <ContainerFlex>
            <ImgContainer item md={6}>
              <img src={image} alt={brandName} style={{ width: '50%' }} />
            </ImgContainer>
            <Grid item md={6}>
              <SmallHeading variant="caption">Molecules</SmallHeading>
              <MoleculeText>{moleculeStr || 'N/A'}</MoleculeText>
              <SmallHeading variant="caption">Description</SmallHeading>
              <NormalText2>{description}</NormalText2>
              <SmallHeading variant="caption">Pack size</SmallHeading>
              <NormalText2>{packSize}</NormalText2>
            </Grid>
          </ContainerFlex>
        </Box>
        <Box p={4} bgcolor="background.paper" borderRadius={4} mt={4}>
          <CardTitle className="erp-uat-product-info">Product Information</CardTitle>
          <TableContainer style={{ marginTop: '1rem' }}>
            <Table style={{ width: '100%' }}>
              <TableHeadStyled>
                <TableRowStyled>
                  <TableCellStyled>Created Date</TableCellStyled>
                  <TableCellStyled>PID</TableCellStyled>
                  <TableCellStyled>Quantity</TableCellStyled>
                  <TableCellStyled>Pack Size</TableCellStyled>
                  <TableCellStyled>Selling Price</TableCellStyled>
                  <TableCellStyled>SKU</TableCellStyled>
                  <TableCellStyled>Expiry Date</TableCellStyled>
                  <TableCellStyled>Status</TableCellStyled>
                  <TableCellStyled>Action</TableCellStyled>
                </TableRowStyled>
              </TableHeadStyled>
              <TableBody>
                <TableCellRow>
                  <TableCellBodyStyled>{datePlaced || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{id || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{quantityInStock || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{packSize || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{sellingPrice || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{sku || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{'Add expiry' || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{getStatus()}</TableCellBodyStyled>
                  <TableCellBodyStyled>
                    <ActionPopper handleAction={handleAction} row={info} />
                  </TableCellBodyStyled>
                </TableCellRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </ContactGrid>

    </HeaderWrapper>
  );
};

ProductInfo.propTypes = {
  refetch: PropTypes.func.isRequired,
  info: PropTypes.instanceOf(Object).isRequired,

};

export default ProductInfo;
