import { styled } from '@mui/material/styles';
import { Button, Dialog, Typography } from '@mui/material';

export const DialogContainer = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    height: 31rem;
    width: 28.813rem;
    border-radius: 12px;
    box-shadow: 0px 2.96px 66.64px 0px rgba(163, 171, 185, 0.12);
    overflow: hidden;
  }
`;

export const TitleText = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 700;
  color: #303030;
`;

export const SubTitleText = styled(Typography)`
  font-size: .875rem;
  font-weight: 400;
  color: #606060;
  padding-top: 1rem;
`;

export const ConfirmButton = styled(Button)`
  border-radius: 5px;
  background: #235a91;
  height: 3rem;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  font-size: .875rem;
  padding: 1.75rem;

  &:hover {
    background: #235a91;
    color: #ffffff;
  }
`;
