import React from 'react';
import {
  useMediaQuery,
  DialogContent,
  Slide,
  Box,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import success from '../../assets/images/Checksuccesful.gif';
import {
  DialogContentStyled,
  TitleStyled,
  DescStyled,
  ButtonDarkStyled,
  FooterStyled,
  DialogContainer
} from './successDialog.style';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const SuccessDialog = ({
  openDialog, setOpenDialog, title, desc, option, refetch, therapeuticType, redirectUrl
}) => {
  const navigate = useNavigate();
  const handleClose = async () => {
    if (refetch) refetch();
    setOpenDialog(false);
    if (redirectUrl !== '') navigate(redirectUrl);
  };

  const isSmall = useMediaQuery('(max-width: 991px)');

  return (
    <DialogContainer
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled therapeuticType={therapeuticType}>
        <Close
          fontSize="small"
          style={{ position: 'absolute', right: '1.5rem', cursor: 'pointer' }}
          onClick={handleClose}
        />
        <DialogContent style={{ marginTop: '2rem' }}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Box marginBottom={4}>
              <img src={success} alt="warning" width={isSmall ? 400 : 170} />
            </Box>
            <TitleStyled>{title}</TitleStyled>
            <DescStyled therapeuticType={therapeuticType}>{desc}</DescStyled>
          </Box>
        </DialogContent>
        <FooterStyled>
          <ButtonDarkStyled
            onClick={handleClose}
            fullWidth
          >
            {option}
          </ButtonDarkStyled>
        </FooterStyled>
      </DialogContentStyled>
    </DialogContainer>
  );
};

SuccessDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  title: PropTypes.string,
  desc: PropTypes.string,
  option: PropTypes.string,
  refetch: PropTypes.func,
  therapeuticType: PropTypes.bool,
  redirectUrl: PropTypes.string
};

SuccessDialog.defaultProps = {
  openDialog: false,
  setOpenDialog: () => false,
  title: '',
  desc: '',
  option: '',
  refetch: () => null,
  therapeuticType: false,
  redirectUrl: ''
};

export default SuccessDialog;
