import { gql } from '@apollo/client';

export const CREATE_CATEGORY_MUTATION = gql`
  mutation createCategory(
    $categoryName: String!
    $loyaltyWeight: String!
    $vatStatus: String!
    $markup: Float
    $reorderPoint: Int
    $reorderMax: Int
    $reorderUnit: String
  ) {
    createCategory(
      categoryName: $categoryName
      loyaltyWeight: $loyaltyWeight
      vatStatus: $vatStatus
      markup: $markup
      reorderPoint: $reorderPoint
      reorderMax: $reorderMax
      reorderUnit: $reorderUnit
    ){
      message
      category {
        id,
        categoryName
        status
        loyaltyWeight
        markup
        vatStatus
      }
    }
  }
`;

export const UPDATE_CATEGORY_MUTATION = gql`
  mutation updateCategory(
    $categoryId: String!
    $categoryName: String!
    $loyaltyWeight: String!
    $vatStatus: String!
    $markup: Float
    $reorderPoint: Int
    $reorderMax: Int
    $reorderUnit: String
  ) {
    updateCategory(
      categoryId: $categoryId
      categoryName: $categoryName
      loyaltyWeight: $loyaltyWeight
      vatStatus: $vatStatus
      markup: $markup
      reorderPoint: $reorderPoint
      reorderMax: $reorderMax
      reorderUnit: $reorderUnit
    ){
      message
      category {
        id
      }
    }
  }
`;

export const CREATE_CLOSE_TILL = gql`
  mutation closeTill(
    $cashTotal: Int!
    $cardTotal: Int!
    $bankTotal: Int!
    $saleAmount: Int!
    $footfall: Int!
    $note: String
    $storeCredit: Float!
  ) {
    closeTill(
      cashTotal: $cashTotal
      cardTotal: $cardTotal
      bankTotal: $bankTotal
      saleAmount: $saleAmount
      footfall: $footfall
      note: $note
      storeCredit: $storeCredit
    ){
      message
    }
  }
`;

export const CREATE_BUSINESS_CATEGORY_MUTATION = gql`
  mutation createBusinessCategory(
    $categoryName: String!
    $markup: Float!
  ) {
    createBusinessCategory(
      categoryName: $categoryName
      markup: $markup
    ){
      message
    }
  }
`;

export const UPDATE_BUSINESS_CATEGORY_MUTATION = gql`
  mutation updateBusinessCategory(
    $categoryId: Int!
    $categoryName: String!
    $markup: Float!
  ) {
    updateBusinessCategory(
      categoryId: $categoryId
      categoryName: $categoryName
      markup: $markup
    ){
      message
    }
  }
`;

export const DELETE_BUSINESS_CATEGORY_MUTATION = gql`
mutation deleteBusinessCategory(
  $id: Int!
) {
  deleteBusinessCategory(
    id: $id
  ){
    message
  }
}
`;

export const DELETE_PRICE_CATEGORY_MUTATION = gql`
mutation deleteCategory(
  $categoryId: String!
) {
  deleteCategory(
    categoryId: $categoryId
  ){
    message
  }
}
`;

export const DELETE_CATEGORIES = gql`
  mutation deleteTherapeuticType($categoryId: Int!) {
    deleteTherapeuticType(categoryId: $categoryId) {
      message
    }
  }  
`;

export const CREATE_CUSTOMER_CATEGORY = gql`
  mutation CreateCustomerCategory($name: String!, $markup: Float!){
    createCustomerCategory(name: $name, markup: $markup){
      message
      category{
        id
        name
        markup
      }
    }
  }
`;

export const UPDATE_CUSTOMER_CATEGORY = gql`
  mutation UpdateCustomerCategory($categoryId: Int!, $name: String!, $markup: Float!){
    updateCustomerCategory(categoryId: $categoryId, name: $name, markup: $markup){
      message
      category{
        id
        name
        markup
      }
    }
  }
`;

export const DELETE_CUSTOMER_CATEGORY = gql`
  mutation DeleteCustomerCategory($categoryId: Int!){
    deleteCustomerCategory(categoryId: $categoryId){
      message
    }
  }
`;

export const ADD_CATEGORY_BUSINESS = gql`
  mutation AddBusinessToCategory($categoryId:Int!, $businessIds: [Int]!){
    addBusinessToCategory(categoryId: $categoryId, businessIds: $businessIds){
      message
      category{
          id
          name
      }
      businesses{
          id
          name
      }
    }
  }
`;
