import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, Slide, Grid, Box
} from '@mui/material';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import CustomButton from '../../../customComponents/customButton';
import {
  CDialog, DialogTitleText, CircularProgressLoader, TextFieldBox, CTextField,
  GridWrapper, CloseIcon, FieldHeader
} from './createCategoryDialog.styles';
import ConfirmDialog from '../confirmDialog';
import SuccessDialog from '../../indevidualCategory/successDialog';
import { CREATE_CUSTOMER_CATEGORY, UPDATE_CUSTOMER_CATEGORY } from '../../../../mutations/categories';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const CreateCategoryDialog = ({
  open, close, isEdit, category, refetch
}) => {
  const initialState = { categoryName: '', markup: '', };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [createCustomerCategory] = useMutation(CREATE_CUSTOMER_CATEGORY);
  const [updateCustomerCategory] = useMutation(UPDATE_CUSTOMER_CATEGORY);

  const [openDialog, setOpenDialog] = useState(false);
  const { categoryName, markup } = state;

  const textFieldHeader = [
    {
      name: 'categoryName', type: 'text',
      label: 'Category Name', placeholder: 'Enter Category Name'
    },
    {
      name: 'markup', type: 'number',
      label: 'Markup (%)', placeholder: 'Enter Markup'
    },
  ];

  useEffect(() => {
    if (category) {
      setState((prevState) => ({
        ...prevState,
        categoryName: category.name,
        markup: category.markup,
      }));
    }
  }, [category]);

  const handleSuccessDialog = () => {
    setSuccessDialog(!successDialog);
  };

  const toggleConfirmation = () => {
    setOpenDialog(!openDialog);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleCreate = () => {
    if (categoryName === '' || markup === '') return toast.error('Category name and markup fields cannot be empty');
    setLoading(true);
    const variables = { name: categoryName.trim(), markup: +markup };
    createCustomerCategory({ variables })
      .then(({ data }) => {
        const { message } = data?.createCustomerCategory || {};
        toast.success(message);
        setState(initialState);
        setSuccessDialog(true);
        refetch();
      })
      .catch((e) => {
        toast.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = () => {
    toggleConfirmation();
  };

  const handleSave = () => {
    if (isEdit) return handleEdit();
    return handleCreate();
  };

  const toggleSuccessModal = () => {
    handleSuccessDialog();
    setState(initialState);
    close();
  };

  const handleUpdate = () => {
    if (categoryName === '' || markup === '') {
      setOpenDialog(false);
      return toast.error('Category name and markup fields cannot be empty');
    }
    const variables = {
      categoryId: Number(category?.id), name: categoryName.trim(), markup: +markup
    };
    updateCustomerCategory({ variables })
      .then(({ data }) => {
        const { message } = data?.updateCustomerCategory || {};
        toast.success(message);
        setState(initialState);
        handleSuccessDialog();
        refetch();
        toggleConfirmation();
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };

  const textField = (field) => {
    const {
      name: fieldName, type, placeholder
    } = field;
    const _value = state[fieldName];
    return (
      <TextFieldBox>
        <CTextField
          variant="outlined"
          value={_value}
          size="large"
          name={fieldName}
          onChange={handleChange}
          type={type}
          placeholder={placeholder}
          fullWidth
          required
          className={`mpAdmin-uat-customerCategory-add-category-${fieldName}`}
          InputProps={{
            style: { height: '3.2rem', fontSize: '.75rem', borderRadius: '.5rem' }
          }}
        />
      </TextFieldBox>
    );
  };

  const handleCloseDialog = () => {
    setState(initialState);
    close();
  };

  return (
    <CDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth
    >
      <GridWrapper container>
        <Grid container style={{ marginTop: '1rem', padding: '1.5rem 1.5rem 1rem 1.5rem' }}>
          <Grid item xs={6}>
            <DialogTitleText container item xs={12}>
              {isEdit ? 'Edit Category' : 'Add Category'}
            </DialogTitleText>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <CloseIcon onClick={handleCloseDialog} />
          </Grid>
        </Grid>
        <Box>
          {textFieldHeader.map((fields) => (
            <Box key={fields}>
              <FieldHeader>
                {fields.label}
              </FieldHeader>
              {textField(fields)}
            </Box>
          ))}
        </Box>

        <DialogActions>
          <CustomButton
            type="tertiary"
            style={{
              width: '11rem', height: '3.2rem', marginRight: '1.2rem',
              fontWeight: '700', fontSize: '.875rem'
            }}
            onClick={handleCloseDialog}
            className="mpAdmin-uat-customerCategory-add-category-cancel"
          >
            Cancel
          </CustomButton>
          <CustomButton
            style={{
              width: '11rem', height: '3.2rem',
              fontWeight: '700', fontSize: '.875rem'
            }}
            onClick={handleSave}
            className="mpAdmin-uat-customerCategory-add-category-save"
          >
            {loading ? (
              <CircularProgressLoader
                disableShrink
                size={22}
                thickness={5}
              />
            ) : (
              isEdit ? 'save' : 'Add Category'
            ) }
          </CustomButton>
        </DialogActions>
      </GridWrapper>
      <SuccessDialog
        openConfirmation={successDialog}
        handleClose={toggleSuccessModal}
        title={isEdit ? 'Category Updated!' : 'Category Added Successfully!'}
        discreption={isEdit ? 'Your category has been successfully updated!' : 'Your category has been successfully added!'}
      />
      <ConfirmDialog
        openConfirmation={openDialog}
        handleClose={toggleConfirmation}
        title="Update Category"
        discreption="Are you sure you want to update this category?"
        buttonTitle="Yes, Update"
        handleConfirm={handleUpdate}
      />
    </CDialog>
  );
};

CreateCategoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  category: PropTypes.instanceOf(Object).isRequired,
  refetch: PropTypes.func.isRequired
};

export default CreateCategoryDialog;
