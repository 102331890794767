import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import toast from 'react-hot-toast';

import {
  Dialog, DialogActions, DialogContent, DialogTitle, Slide,
  Grid
} from '@mui/material';
import { useMutation } from '@apollo/client';
import ReturnSelectField from './returnSelectField';
import SavePopper from './savePopper';
import {
  DialogCancelButton, DialogOkButton, DialogActionButtonText, DialogTitleText,
  DialogTitleSubText, DialogTitleWrapper, CircularProgressLoader,
  GridWrapper, TopGrid, MiddleGrid, BottomGrid, SupplierTextField
} from './individualSubBusinessDialog.styles';
import { validateEmail } from '../../auth/utils';
import AffiliateActionTypes from '../../../providers/reducers/affiliate/affiliateTypes';

import { useStateValue } from '../../../providers/stateProvider';
import { CREATE_SUB_BUSINESS_MUTATION } from '../../../mutations/business';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const IndividualSubBusinessDialog = ({
  dialogOpen, closeDialog
}) => {
  const today = moment().format('YYYY-MM-DD');
  const initialState = {
    outletName: '',
    businessType: '',
    mobile: '',
    primaryContactName: '',
    dateLaunched: today,
    email: '',
    addressLine1: '',
    addressLine2: '',
    region: '',
    city: 'Lagos',
    country: 'Nigeria',
  };

  const initialErrorState = {
    nameError: false,
    emailError: false,
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [saveButtonEl, setSaveButtonEl] = useState(null);
  const [editing, setEditing] = useState(false);

  const [, dispatch] = Object.values(useStateValue());

  const [createSubBusiness] = useMutation(CREATE_SUB_BUSINESS_MUTATION);

  const { nameError } = errorState;

  const handleCloseDialog = () => {
    if (editing) setEditing(false);
    setState(initialState);
    dispatch({
      type: AffiliateActionTypes.UPDATE_AFFILIATE,
      payload: { affiliate: null }
    });
    closeDialog();
  };

  const addAffiliate = (_, addMore = false) => {
    const {
      outletName, businessType, mobile, dateLaunched, email,
      addressLine1, primaryContactName, region, city, country,
    } = state;
    createSubBusiness({
      variables: {
        name: outletName,
        businessType,
        dateLaunched,
        primaryContactName,
        mobileNumber: mobile,
        primaryEmailAddress: email,
        addressLine1,
        region,
        city,
        country,
      }
    })
      .then(({ data }) => {
        const { message } = data?.createSubBusiness || {};
        toast.success(message);
        if (!addMore) {
          handleCloseDialog();
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = async () => {
    setSaveButtonEl(null);
    setLoading(true);
    return addAffiliate();
  };

  const handleSaveContinue = async () => {
    const addMore = true;
    await handleSave(addMore);
    setState(initialState);
  };

  const handleSaveButtonClick = (event) => {
    const { outletName } = state;
    if (outletName.length > 1 && !editing) {
      return setSaveButtonEl(saveButtonEl ? null : event.currentTarget);
    } if (outletName.length > 1 && editing) {
      return handleSave();
    }
    return setErrorState({
      ...errorState,
      nameError: true
    });
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'legalName':
        return setErrorState({
          ...errorState,
          nameError: !(value.length > 1)
        });
      case 'email':
        return setErrorState({
          ...errorState,
          emailError: validateEmail(value)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const topFields = [
    {
      name: 'outletName',
      label: 'Outlet Name',
      helperText: 'Outlet Name required'
    },
    {
      name: 'businessType',
      label: 'Outlet Type',
      options: ['Storefront', 'Warehouse']
    },
    { name: 'primaryContactName', label: 'Primary Contact Name' },
    { name: 'email', label: 'Email' },
    { name: 'mobile', label: 'Mobile' },
    { name: 'dateLaunched', label: 'Date Launched' },
  ];

  const middleFields = [
    { name: 'addressLine1', label: 'Address Line 1' },
    { name: 'addressLine2', label: 'Address Line 2' }
  ];

  const bottomFields = [
    { name: 'region', label: 'Region' },
    { name: 'city', label: 'City' },
    {
      name: 'country',
      label: 'Country',
      options: ['Nigeria', 'Uganda', 'Kenya']
    }
  ];
  const returnTextField = (field) => {
    const { name: fieldName, label, helperText } = field;
    const value = state[fieldName];
    if (['country', 'businessType'].includes(fieldName)) {
      return (
        <ReturnSelectField
          field={field}
          value={value}
          handleChange={handleChange}
        />
      );
    }
    return (
      <SupplierTextField
        variant="filled"
        size="small"
        label={label}
        value={value}
        type={fieldName === 'mobile' ? 'number' : fieldName === 'dateLaunched' ? 'date' : 'text'}
        error={fieldName === 'legalName' ? nameError : false}
        helperText={nameError && helperText}
        name={fieldName}
        onChange={handleChange}
      />
    );
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        fileslimit={1}
      >
        <Grid container>
          <GridWrapper container item xs>
            <DialogTitle id="alert-dialog-slide-title">
              <DialogTitleWrapper container>
                <DialogTitleText>
                  {editing ? 'Edit Outlet' : 'Add Outlet' }
                </DialogTitleText>
                <DialogTitleSubText>
                  {editing
                    ? 'Edit the form to update outlet information'
                    : 'Add your outlet information to the fields in the form'}
                </DialogTitleSubText>
              </DialogTitleWrapper>
            </DialogTitle>
            <DialogContent>
              <TopGrid item>
                {topFields.map((field) => returnTextField(field))}
              </TopGrid>
              <MiddleGrid item>
                {middleFields.map((field) => returnTextField(field))}
              </MiddleGrid>
              <BottomGrid item>
                {bottomFields.map((field) => returnTextField(field))}
              </BottomGrid>
            </DialogContent>

            <DialogActions>
              <DialogCancelButton
                onClick={handleCloseDialog}
              >
                <DialogActionButtonText>
                  Cancel
                </DialogActionButtonText>
              </DialogCancelButton>

              <DialogOkButton
                onClick={handleSaveButtonClick}
              >
                {loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={22}
                    thickness={5}
                  />
                ) : (
                  <DialogActionButtonText>
                    {editing ? 'Save Changes' : 'Save & ...'}
                  </DialogActionButtonText>
                )}
              </DialogOkButton>
            </DialogActions>
          </GridWrapper>
        </Grid>
      </Dialog>

      <SavePopper
        saveButtonEl={saveButtonEl}
        setSaveButtonEl={setSaveButtonEl}
        handleSave={handleSave}
        handleSaveContinue={handleSaveContinue}
      />
    </>
  );
};

IndividualSubBusinessDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default IndividualSubBusinessDialog;
