import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { useMediaQuery } from '@mui/material';

const NewOrdersLoader = ({
  width,
  heading,
  row,
  column,
  padding,
  borderRadius,
  ...props
}) => {
  const isSmall = useMediaQuery('(max-width: 991px)');
  if (isSmall) {
    width = 900;
    column = 2;
  }

  const list = [];
  let height;

  for (let i = 1; i <= row; i++) {
    for (let j = 0; j < column; j++) {
      const itemWidth = (width - padding * (column + 1)) / column;

      const x = padding + j * (itemWidth + padding);

      const height1 = itemWidth;

      const height2 = 20;

      const height3 = 20;

      const space = padding * 2 + height1;

      const y1 = padding + space * (i - 1);

      const y2 = y1 + padding + height1;

      const y3 = y2 + padding / 2 + height2;

      list.push(
        <rect
          x={x}
          y={y1}
          rx={borderRadius}
          ry={borderRadius}
          width={itemWidth}
          height={height1}
        />
      );

      if (i === row) {
        height = y3 + height3;
      }
    }
  }

  return (
    <ContentLoader
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      speed={2}
      backgroundColor="#cccccc"
      foregroundColor="#ecebeb"
      {...props}
    >
      {list}
    </ContentLoader>
  );
};

NewOrdersLoader.propTypes = {
  width: PropTypes.number,
  heading: PropTypes.instanceOf(Object),
  row: PropTypes.number,
  column: PropTypes.number,
  padding: PropTypes.number,
  borderRadius: PropTypes.number,
};

NewOrdersLoader.defaultProps = {
  width: 1120,
  heading: { width: 140, height: 24 },
  row: 4,
  column: 4,
  padding: 12,
  borderRadius: 4,
};

export default NewOrdersLoader;
