import React from 'react';
import {
  DialogActions, DialogContent, DialogContentText,
  Slide,
} from '@mui/material';
import PropTypes from 'prop-types';
import success from '../../assets/images/Checksuccesful.gif';
import {
  CloseIcon, DialogContainer, DialogContentBox, StyledButton, SubtitleText, SuccessImageContainer, TitleText,
  ImageStyle
} from './cartSaved.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const CartSaved = ({
  cartSaved,
  handleClose
}) => (
  <DialogContainer
    open={cartSaved}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-describedby="alert-dialog-slide-description"
    PaperProps={{
      style: { borderRadius: 10 }
    }}
  >
    <DialogContent>
      <CloseIcon fontSize="small" onClick={handleClose} />
      <DialogContentText>
        <DialogContentBox>
          <SuccessImageContainer>
            <ImageStyle src={success} alt="success" />
          </SuccessImageContainer>
          <TitleText variant="h6">Cart Saved!</TitleText>
          <SubtitleText variant="subtitle1">
            Hi Pharm, Your Cart has been saved successfully!
          </SubtitleText>
        </DialogContentBox>
      </DialogContentText>
      <DialogActions style={{ marginBottom: '2rem' }}>
        <StyledButton onClick={handleClose} size="large">
          Ok
        </StyledButton>
      </DialogActions>
    </DialogContent>
  </DialogContainer>
);

CartSaved.propTypes = {
  cartSaved: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default CartSaved;
