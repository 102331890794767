import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  StaticFooter,
  StaticFooterSection,
  StaticFooterText,
} from './placeOrder.styles';
import { ContinueButton } from './paymentSummary.styles';
import currencyFormatter from '../../shared/currencyFormatter';

const PlaceOrderAction = ({ disabled, placeOrder, total }) => {
  const navigate = useNavigate();
  return (
    <StaticFooter>
      <StaticFooterSection onClick={placeOrder} disabled={disabled}>
        <StaticFooterText>
          {/* {loading ? (
            <CircularProgressLoader disableShrink size={24} thickness={4} />
          ) : (
            'Place Order'
          )} */}
           {`Place Order (₦ ${currencyFormatter(total)})`}
        </StaticFooterText>

      </StaticFooterSection>
      <ContinueButton
        fullWidth
        onClick={() => navigate('/new-order')}
        className="mpAdmin-uat-cartPage-continueShopping"
      >
        Continue Shopping
      </ContinueButton>
    </StaticFooter>
  );
};

PlaceOrderAction.propTypes = {
  disabled: PropTypes.bool.isRequired,
  placeOrder: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired
};

export default PlaceOrderAction;
