import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CartBag } from '../../../../assets/svgs';

export const StaticFooter = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: #ffffff;
  padding: 2.1rem 4rem;
`;

export const StaticFooterSection = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #235A91;
  width: 100%;
  border-radius: 10px;
  padding: 2rem 0;
`;

export const StaticFooterIcon = styled('div')``;

export const StaticFooterText = styled(Typography)`
  font-size: 2rem;
  font-weight: 500;
  color: #fff;
`;

export const ShoppingCartImg = styled(CartBag)`
color: ${(props) => (props.outOfStock ? '#92C5E7' : ' #235A91')};
&.MuiSvgIcon-root {
  width: 17px;
  height: 15px;
}

@media (max-width: 991px) {
  &.MuiSvgIcon-root {
    width: 35px;
    height: 35px;
  }
}
`;
