import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { VERIFY_ACCOUNT_MUTATION } from '../../../mutations/verifyUser';
import {
  BodyCell, MainTableRow, ColorGrid, Dot,
} from './returnRow.styles';
import ActionPopper from './actionPopper';
import MarkupDialog from './markupDialog';
import ConfirmDialog from '../../shared/confirmDialog';
import SuccessDialog from '../../shared/successDialog';

const ReturnRow = ({
  row, index, refetch, tab
}) => {
  const [action, setAction] = useState(null);
  const [openMarkupDialog, setOpenMarkupDialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [markup, setMarkup] = useState('');
  const [category, setCategory] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate();
  const {
    id, dateCreated, location, businessuserSet, businessType
  } = row;

  const {
    name: businessName,
    buyerMeta: { verificationStatus: buyerStatus },
    sellerMeta: { verificationStatus: sellerStatus }
  } = businessuserSet[0]?.business || {};

  const status = tab === 'BUYER' ? buyerStatus : sellerStatus;

  const handleNavigate = () => {
    if (tab === 'BUYER') {
      return navigate(`/manage-account/buyer/${id}/details`);
    }
    return navigate(`/manage-account/seller/${id}/details`);
  };

  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const getColorGrid = (color, bkg, userStatus) => (
    <ColorGrid item container>
      <Dot background={bkg} />
      {userStatus}
    </ColorGrid>
  );
  const getStatus = () => {
    switch (status) {
      case 'ACTIVE':
        return getColorGrid('#606060', '#00B588', 'Verified');
      case 'IN_ACTIVE':
        return getColorGrid('#606060', '#F29053', 'Unverified');
      case 'SUSPENDED':
        return getColorGrid('#606060', '#FF0F0F', 'Suspended');
      default:
        return getColorGrid('white', '#BF0505', 'N/A');
    }
  };

  const cells = [businessName, businessType.toLowerCase(), location.toLowerCase()];

  const returnCell = (val) => val || 'N/A';
  const renderBodyCells = () => cells.map((cell) => (
    <BodyCell
      key={cell}
      style={{ cursor: 'pointer', textTransform: 'capitalize' }}
      onClick={handleNavigate}
    >
      {returnCell(cell)}
    </BodyCell>
  ));

  const [verifyAccount, { loading }] = useMutation(VERIFY_ACCOUNT_MUTATION);

  const verifyHandler = () => {
    let variables = {};
    const { id: catId } = category;
    if (tab === 'SELLER') {
      variables = {
        businessId: Number(id),
        businessType: tab.toUpperCase(),
        markup: Number(markup)
      };
    } else {
      variables = {
        businessId: Number(id),
        businessType: tab.toUpperCase(),
        categoryId: Number(catId)
      };
    }
    verifyAccount({
      variables: { ...variables }
    }).then(({ data }) => {
      const { message } = data.verifyBusiness;
      setOpenDialog(true);
      setOpenModal(false);
      toast.success(message);
      refetch();
    }).catch((err) => toast.error(err.message));
  };

  const markupHandler = () => {
    setOpenMarkupDialog(!openMarkupDialog);
  };

  const confirmDialogHandler = () => {
    setOpenModal(true);
    setOpenMarkupDialog(false);
  };

  const handleAction = (event, type) => {
    if (type === 'verify') {
      markupHandler();
    }
    handleOptions(event);
  };

  const handleCloseMarkup = () => {
    setMarkup('');
    setCategory({});
    setOpenMarkupDialog(false);
  };

  const handleCloseConfirm = () => {
    setMarkup('');
    setCategory({});
    setOpenModal(false);
  };

  return (
    <>
      <MainTableRow style={index % 2 ? { backgroundColor: ' #F3F9FF' } : { backgroundColor: '#FFF' }}>
        <BodyCell onClick={handleNavigate} />
        <BodyCell onClick={handleNavigate}>
          {dayjs(dateCreated).format('DD/MM/YYYY (h:s)')}
        </BodyCell>
        {renderBodyCells()}
        <BodyCell onClick={handleNavigate}>
          {getStatus()}
        </BodyCell>
        <BodyCell>
          {
          loading ? 'loading...' : (
            <ActionPopper
              handleAction={handleAction}
              row={row}
              navigate={handleNavigate}
              status={status}
            />
          )
        }
        </BodyCell>
      </MainTableRow>
      <MarkupDialog
        setSellerMarkup={setMarkup}
        openDialog={openMarkupDialog}
        setOpenDialog={handleCloseMarkup}
        confirmDialogHandler={confirmDialogHandler}
        category={category}
        setCategory={setCategory}
        tab={tab}
        location={location}
      />

      <ConfirmDialog
        openDialog={openModal}
        setOpenDialog={handleCloseConfirm}
        title="Verify Account?"
        desc="Are you sure you want to verify this account?"
        options={['Cancel', 'Yes, Verify']}
        buttonAction={verifyHandler}
      />

      <SuccessDialog
        openDialog={openDialog}
        setOpenDialog={() => setOpenDialog(false)}
        title="Account Verified!"
        desc="This account has been verified successfully!"
        option="Ok"
      />
    </>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  index: PropTypes.instanceOf(Number),
  refetch: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired
};

ReturnRow.defaultProps = {
  row: {},
  index: 0,
};

export default ReturnRow;
