import React from 'react';
import {
  StaticFooter,
  StaticFooterSection,
  StaticFooterText,
  ShoppingCartImg
} from './addProductToCart.styles';
import { CircularProgressLoader } from './productDetail.styles';

const AddProductToCart = ({ loading, handleAddToCart }) => (
  <StaticFooter>
    <StaticFooterSection onClick={handleAddToCart}>
      <ShoppingCartImg />
      <StaticFooterText>
        {loading ? (
          <CircularProgressLoader disableShrink size={24} thickness={4} />
        ) : (
          'Add to Cart'
        )}
      </StaticFooterText>
    </StaticFooterSection>
  </StaticFooter>
);

export default AddProductToCart;
