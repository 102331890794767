import { Close } from '@mui/icons-material';
import {
  Box, Button, Dialog, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Dialog)`
  @media (max-width: 991px) {
    & .MuiDialog-paper {
      width: 90vw;
      height: fit-content;
      margin: 0;
      max-width: 100%;
    }
  }
`;

export const CloseIcon = styled(Close)`
  position: absolute;
  right: 1.5rem;
  cursor: pointer;

  @media (max-width: 991px) {
    font-size: 4rem;
  }
`;

export const DialogContentBox = styled(Box)`
  padding: 5rem 0 1rem 0;
  width: 100%;
  text-align: center;
`;

export const SuccessImageContainer = styled(Box)`
  margin-bottom: 4rem;
`;

export const TitleText = styled(Typography)`
  font-weight: 700;
  color: #303030;
  font-size: 20px;

  @media (max-width: 991px) {
    font-size: 2.5rem;
  }
`;

export const SubtitleText = styled(Typography)`
  padding-top: 1rem;
  font-size: 14px;
  font-weight: 400;

  @media (max-width: 991px) {
    font-size: 2.3rem;
  }
`;

export const StyledButton = styled(Button)`
  background: #235a91;
  color: #fff;
  padding: 0.5rem 2rem;
  width: 100%;

  @media (max-width: 991px) {
    font-size: 2rem;
    font-weight: 600;
    padding: 1rem 2rem;
    margin-top: 3rem 0;
  }
`;

export const ImageStyle = styled('img')`
  width: 155px;

  @media (max-width: 991px) {
    width: 400px;
  }
`;
