import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { CartDot } from '../../assets/svgs';
import {
  MenuItemStyled
} from './actionPopper.styles';
import { OgaToken } from '../../graphql/token';
import { getAxios } from '../../axios';

const ActionPopper = ({
  handleAction, handleActionView, cart, handleNoCart, cartOrder, handleUploadDescDialog
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewSaveCartWithData = () => {
    handleActionView();
    handleClose();
  };
  const NoCart = () => {
    handleNoCart();
    handleClose();
  };

  const handleViewSaveCart = () => {
    if (!cartOrder?.length) {
      return NoCart();
    }
    return handleViewSaveCartWithData();
  };

  const downloadCartPDF = () => {
    const serverUrl = `${OgaToken.SERVER_LINK}`;
    const token = localStorage.getItem('oga_rest_token');
    getAxios().get(`${serverUrl}print-cart`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      responseType: 'blob',
    })
      .then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'cart-items';
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        toast.error(err.message);
      });
    handleClose();
  };

  const isSmall = useMediaQuery('(max-width: 991px)');

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <CartDot style={{ fontSize: isSmall ? '5.5rem' : '3rem' }} />
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItemStyled
          onClick={(e) => {
            handleAction(e);
            handleClose();
          }}
          disabled={!cart?.length}
        >
          Save Cart
        </MenuItemStyled>
        <MenuItemStyled onClick={handleViewSaveCart}>View Saved Cart</MenuItemStyled>
        <MenuItemStyled onClick={downloadCartPDF}>Download Cart PDF</MenuItemStyled>
        <MenuItemStyled onClick={(e) => {
          handleUploadDescDialog();
          handleClose();
        }}
        >
          Upload Bulk Order CSV
        </MenuItemStyled>
      </Menu>
    </div>
  );
};

ActionPopper.propTypes = {
  handleAction: PropTypes.func.isRequired,
  handleActionView: PropTypes.func.isRequired,
  cart: PropTypes.arrayOf.isRequired,
  cartOrder: PropTypes.arrayOf.isRequired,
  handleNoCart: PropTypes.func.isRequired,
  handleUploadDescDialog: PropTypes.func.isRequired,
};

export default ActionPopper;
