import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Box, TableContainer, Table, TableBody
} from '@mui/material';
import { useQuery } from '@apollo/client';
import TablePagination from '../../shared/tablePagination';
import TableLoader from '../../shared/tableLoader';
import { PRODUCT_ACTION_TIMELINE } from '../../../queries/products';
import {
  ContactGrid,
} from './productActionTimeline.style';
import {
  CardTitle, TableHeadStyled, TableRowStyled, TableCellStyled, TableCellBodyStyled, TableCellRow
} from './productInfo.style';

const ProductActionTimeline = ({ id }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const {
    loading, error, data
  } = useQuery(PRODUCT_ACTION_TIMELINE, {
    fetchPolicy: 'cache-and-network',
    variables: {
      productId: Number(id),
      pageNumber,
      pageCount,
    },
  });

  // if (loading || error) return 'Loading...';

  const actionTimeline = data?.productActionTimelines || [];
  const totalRecord = data?.productActionTimelinesTotalNumber || 0;

  const getDate = (date) => moment(date).format('MMM-DD-YYYY HH:mm');

  return (
    <ContactGrid item xs={12} md={12}>
      <Box p={4} bgcolor="background.paper" borderRadius={4}>
        <CardTitle className="erp-uat-action-timeline">Action Timeline</CardTitle>
        { loading || error ? <TableLoader rows={5} />
          : (
            <TableContainer style={{ marginTop: '1rem' }}>
              <Table style={{ width: '100%' }}>
                <TableHeadStyled>
                  <TableRowStyled>
                    <TableCellStyled>Last Updated Field</TableCellStyled>
                    <TableCellStyled>Previous Value</TableCellStyled>
                    <TableCellStyled>Updated Value</TableCellStyled>
                    <TableCellStyled>Last Update Date</TableCellStyled>
                    <TableCellStyled>Last Updated By</TableCellStyled>
                  </TableRowStyled>
                </TableHeadStyled>
                <TableBody>
                  {
                actionTimeline.length
                  ? actionTimeline?.map((row, key) => (
                    <TableCellRow key={key}>
                      <TableCellBodyStyled>{row?.lastUpdatedField}</TableCellBodyStyled>
                      <TableCellBodyStyled>{row?.previousValue}</TableCellBodyStyled>
                      <TableCellBodyStyled>{row?.updatedValue}</TableCellBodyStyled>
                      <TableCellBodyStyled>{getDate(row?.lastUpdatedDate)}</TableCellBodyStyled>
                      <TableCellBodyStyled>{row?.lastUpdatedBy}</TableCellBodyStyled>
                    </TableCellRow>
                  ))
                  : (
                    <TableCellRow>
                      <TableCellBodyStyled colSpan={6} style={{ textAlign: 'center' }}>No Record Found</TableCellBodyStyled>
                    </TableCellRow>
                  )
              }
                </TableBody>
              </Table>
            </TableContainer>
          )}
        <div style={{ marginTop: '2rem' }} />
        {totalRecord > 0 && (
        <TablePagination
          total={totalRecord}
          pageCount={pageCount}
          setPageCount={setPageCount}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
        )}
      </Box>
    </ContactGrid>
  );
};

ProductActionTimeline.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ProductActionTimeline;
