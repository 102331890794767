import { gql } from '@apollo/client';

export const VERIFY_ACCOUNT_MUTATION = gql`
  mutation verifyBusiness($businessId: Int!, $businessType: BusinessEnum!, $markup: Float, $categoryId: Int) {
    verifyBusiness(businessId: $businessId, businessType: $businessType, markup: $markup, categoryId: $categoryId) {
      message
      business {
        status
        verifiedBy {
          email
        }
      }
    }
  }
`;
