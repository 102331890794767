import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { KeyboardArrowDown } from '@mui/icons-material';
import toast from 'react-hot-toast';
import {
  SubText, SideDesign, ProfileText, ProfilePaper, SettingsBox, BusinesssName,
  SettingsPaper, HeaderBox, VerifyBox, Bullet, VerifiedText,
  AdornmentStyled, CircularProgressLoader, EditButton, EditButtonText,
} from './sellerSettings.styles';
import { Editicon, SettingsIcon, SaveUpdateIcon } from '../../../../assets/svgs';
import { CustomInputBase, CustomSelectInputBase } from '../customInput';
import IdUploads from '../../../manageAccounts/accountDetails/seller/idUpload';
import { UPDATE_BUSINESS_PROFILE } from '../../../../mutations/business';
import { JSONParse } from '../../../../utils/json';
import SuccessDialog from '../../../shared/successDialog';
import { validateEmailAddress } from '../../../../utils/funcs';

const SellerSettings = ({ userData, businessId, accountType }) => {
  const initialState = {
    businessName: '',
    contactPersonName: '',
    emailAddress: '',
    contactPersonPhoneNumber: '',
    phoneNumber: '',
    salesRep: '',
    registrationNumber: '',
    territory: '',
    businessType: '',
    storeAddress: '',
    fullname: '',
    landmark: '',
    ownersEmailAddress: '',
    city: '',
    ownersPhoneNumber: '',
    state: '',
    gender: ''
  };

  const initialErrorState = {
    businessNameError: false,
    contactPersonNameError: false,
    emailAddressError: false,
    contactPersonPhoneNumberError: false,
    phoneNumberError: false,
    salesRepError: false,
    registrationNumberError: false,
    territoryError: false,
    businessTypeError: false,
    storeAddressError: false,
    fullnameError: false,
    landmarkError: false,
    ownersEmailAddressError: false,
    cityError: false,
    ownersPhoneNumberError: false,
    stateError: false,
    genderError: false
  };

  const initialFiles = {
    cac: {
      url: '',
      fileName: '',
      fileSize: '',
    },
    nafdac: {
      url: '',
      fileName: '',
      fileSize: '',
    },
    pharmacyCouncilRegistrationLicence: {
      url: '',
      fileName: '',
      fileSize: '',
    }
  };

  const [state, setState] = useState(initialState);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [editing, setEditing] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateBusinessInfo] = useMutation(UPDATE_BUSINESS_PROFILE);
  const [loading, setLoading] = useState(false);
  const [phoneNumHelperText, setPhoneNumHelperText] = useState('Phone number required');
  const [emailHelperText, setEmailHelperText] = useState('Email Adress required');
  const [filesField, setFilesField] = useState(initialFiles);

  const validPhoneNumber = (str) => (str.length < 9 || str.length > 13);

  const phoneAdornment = (
    <AdornmentStyled>
      <span>NG</span>
      <KeyboardArrowDown />
    </AdornmentStyled>
  );

  const {
    name: businessName, contactPersonName: contactName, emailAddress: userEmail, contactPersonPhoneNumber,
    phoneNumber: userPhoneNumber, salesRep, registrationNumber, territory,
    businessType, storeAddress, fullname: userFullName, landmark: userLandMark,
    city: userCity, gender
  } = state;

  const {
    businessNameError, contactPersonNameError, emailAddressError, contactPersonPhoneNumberError,
    phoneNumberError, salesRepError, registrationNumberError,
    businessTypeError, storeAddressError, fullnameError, landmarkError, cityError,
  } = errorState;

  const {
    name, fullname, location, sellerMeta, sellerContact, createdBy,
    contact, settings, businessType: defaultBusinessType
  } = userData?.business || {};

  const {
    city, contactPersonName, landmark, phoneNumber, emailAddress,
    storeAddress: userStoreAddress, salesRepresentativeName
  } = sellerContact || {};

  const { meta, email } = createdBy || {};
  const parseMeta = JSONParse(meta);
  const parseContact = JSONParse(contact);
  const parseSettings = JSONParse(settings);
  const { gender: userGender } = parseMeta;
  const { seller_contact: sellerCon } = parseContact;
  const { contact_person_phone_number: contactPersonNumber } = sellerCon || {};
  const { kyc } = parseSettings;
  const { territory: userTerritory, business_type: busType, files } = kyc || {};

  useEffect(() => {
    const { registrationNumber: regNum } = sellerMeta || {};
    setState({
      ...state, name, state: location, city, contactPersonName, landmark,
      phoneNumber, emailAddress, registrationNumber: regNum, fullname,
      gender: userGender, salesRep: salesRepresentativeName, storeAddress: userStoreAddress,
      contactPersonPhoneNumber: contactPersonNumber, businessType: busType ?? defaultBusinessType,
      territory: userTerritory, ownersEmailAddress: email
    });
    setFilesField((prev) => ({ ...prev, ...sellerMeta }));
  }, [sellerMeta, userData]);

  const {
    cac: { url: cacUrl, fileName: cacFileName, fileSize: cacFileSize },
    pharmacyCouncilRegistrationLicence: { url: pcrUrl, fileName: pcrFileName, fileSize: pcrFileSize },
    verificationStatus: status
  } = filesField;

  const {
    url: nafdacUrl,
    fileName: nafdacFileName,
    fileSize: nafdacFileSize
  } = filesField?.nafdac || {};

  const handleEditInfo = () => {
    setLoading(true);
    const updatedInfo = {
      businessName, userFullName, userPhoneNumber, businessType, contactPersonName: contactName,
      gender, landmark: userLandMark, salesRepresentativeName: salesRep, city: userCity,
      contactPersonPhoneNumber, storeAddress, territory, deliveryEmail: userEmail,
      registrationNumber
    };

    updateBusinessInfo({
      variables: {
        businessId, updatedInfo, accountType
      }
    })
      .then(() => {
        setOpenDialog(true);
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setEditing(false);
        setLoading(false);
      });
  };

  const handleSaveButtonClick = () => {
    if (!editing) return setEditing(true);

    if (businessName === '') {
      toast.error('Store Name is required');
    } else if (salesRep === '') {
      toast.error('Sales Representative is required');
    } else if (contactName === '') {
      toast.error('Contact Person Full Name is required');
    } else if (userPhoneNumber === '') {
      toast.error('Phone Number is required');
    } else if (userLandMark === '') {
      toast.error('Landmark is required');
    } else if (userCity === '') {
      toast.error('City is required');
    } else if (businessType === '') {
      toast.error('Business Type is required');
    } else if (storeAddress === '') {
      toast.error('Store Address is required');
    } else if (userEmail === '') {
      toast.error('Email Address is required');
    } else if (registrationNumber === '') {
      toast.error('Registration Number is required');
    } else if (!validateEmailAddress(userEmail)) {
      toast.error('Enter a valid Email Address');
      setEmailHelperText('Enter a valid Email Address');
      return setErrorState({ ...errorState, emailAddressError: !validateEmailAddress(userEmail) });
    } else if (validPhoneNumber(userPhoneNumber)) {
      toast.error('Enter a valid Phone Number');
      setPhoneNumHelperText('Enter a valid Phone Number');
      return setErrorState({ ...errorState, phoneNumberError: validPhoneNumber(userPhoneNumber) });
    } else if (contactPersonPhoneNumber?.length > 0 && validPhoneNumber(contactPersonPhoneNumber)) {
      toast.error('Enter a valid Phone Number');
      setEmailHelperText('Enter a valid Phone Number');
      return setErrorState({ ...errorState, contactPersonPhoneNumberError: validPhoneNumber(contactPersonPhoneNumber) });
    } else {
      return handleEditInfo();
    }
    return setErrorState({
      ...errorState,
      businessNameError: businessName === '',
      businessTypeError: businessType === '',
      contactPersonNameError: contactName === '',
      emailAddressError: userEmail === '',
      salesRepError: salesRep === '',
      landmarkError: userLandMark === '',
      cityError: userCity === '',
      phoneNumberError: userPhoneNumber === '',
      registrationNumberError: registrationNumber === '',
      storeAddressError: storeAddress === '',
      fullnameError: fullname === '',
    });
  };

  const validateState = (field, value) => {
    switch (field) {
      case 'fullname':
      case 'businessName':
      case 'salesRep':
      case 'contactPersonName':
      case 'phoneNumber':
      case 'landmark':
      case 'state':
      case 'city':
      case 'businessType':
      case 'emailAddress':
      case 'registrationNumber':
        return setErrorState({
          ...errorState,
          [`${field}Error`]: !(value.length > 0)
        });
      case 'name':
        setErrorState({
          ...errorState,
          businessNameError: !(value.length > 0)
        });
        break;
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name: fieldName, value } = event.target;
    let inputValue = value;
    if (fieldName === 'phoneNumber' || fieldName === 'contactPersonPhoneNumber') {
      inputValue = inputValue.replace(/[^0-9]/g, '');
    }

    if (fieldName === 'registrationNumber') {
      inputValue = inputValue.replace(/[^0-9/]/g, '');
    }
    validateState(fieldName, inputValue);
    setState({ ...state, [fieldName]: inputValue });
  };

  const handleSelectChange = (event) => {
    const { name: fieldName, value } = event.target;
    const valueMap = {
      Retail: 'RETAILER',
      Retail_Chain: 'RETAIL_CHAIN',
      Wholesale: 'WHOLESALER',
      Distributor: 'DISTRIBUTOR',
      Institution: 'INSTITUTION',
      Private_Hospital: 'PRIVATE_HOSPITAL',
      PPMV: 'PPMV',
    };
    const newValue = valueMap[value] || 'RETAILER';
    setState({ ...state, [fieldName]: newValue });
  };

  const formFields = [
    {
      name: 'name', label: 'Store Name', placeholder: 'e.g Kings Pharmacy', required: true,
      secured: !editing, error: businessNameError, helperText: 'Store Name required',
    },
    {
      name: 'contactPersonName', label: 'Contact Person Full Name', placeholder: 'e.g John Doe', required: true,
      secured: !editing, error: contactPersonNameError, helperText: 'Contact Person Full Name required',
    },
    {
      name: 'emailAddress', label: 'Email Address', placeholder: 'e.g Maestropaul@example.com', required: true,
      secured: !editing, error: emailAddressError, helperText: emailHelperText,
    },
    {
      name: 'contactPersonPhoneNumber', label: 'Contact Person Phone Number', placeholder: 'e.g 07012345678', type: 'number',
      secured: !editing, sAdornment: phoneAdornment, error: contactPersonPhoneNumberError, helperText: phoneNumHelperText,
    },
    {
      name: 'phoneNumber', label: 'Phone Number', placeholder: 'e.g 7012345678', type: 'number', required: true,
      secured: !editing, sAdornment: phoneAdornment, error: phoneNumberError, helperText: phoneNumHelperText,
    },
    {
      name: 'salesRep', label: 'Sales Representative', placeholder: 'e.g John Doe', required: true,
      secured: !editing, error: salesRepError, helperText: 'Sales Representative required',
    },
    {
      name: 'registrationNumber', label: 'Company Registration Number', placeholder: 'e.g 198605/0726/027', type: 'number',
      secured: !editing, error: registrationNumberError, helperText: 'Registration Number required', required: true,
    },
    {
      name: 'territory', label: 'Territory', placeholder: 'e.g Ikeja',
      secured: !editing,
    },
    {
      name: 'businessType', label: 'Business Type', placeholder: 'e.g Retail', required: true,
      secured: true, onChange: handleSelectChange, error: businessTypeError, helperText: 'Business Type required',
      options: ['Retail', 'Retail_Chain', 'Wholesale', 'Distributor', 'Institution', 'Private_Hospital', 'PPMV']
    },
    {
      name: 'storeAddress', label: 'Store Address', placeholder: 'e.g 10B Adeola Odeku Street, Victoria Island, Lagos State',
      secured: !editing, error: storeAddressError, helperText: 'Store Address required', required: true,
    },
    {
      name: 'fullname', label: "Owner's Name", placeholder: 'e.g Kings Pharmacy', required: true,
      secured: !editing, error: fullnameError, helperText: 'Owner name required',
    },
    {
      name: 'landmark', label: 'Landmark', placeholder: 'e.g ICM Ikeja', required: true,
      secured: !editing, error: landmarkError, helperText: 'Landmark required',
    },
    {
      name: 'ownersEmailAddress', label: "Owner's Email Address", placeholder: 'e.g Maestropaul@example.com',
      secured: true,
    },
    {
      name: 'city', label: 'City', placeholder: 'e.g Ikeja', required: true,
      secured: !editing, error: cityError, helperText: 'City required',
    },
    {
      name: 'state', label: 'State', placeholder: 'e.g Lagos & Ibadan',
      secured: true,
    },
    {
      name: 'gender', label: "Owner's Gender", placeholder: 'e.g Male',
      secured: !editing, options: ['Male', 'Female']
    },
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, label, required, error, helperText, secured, placeholder, onChange, val, type, sAdornment
    } = field;

    const value = state[fieldName];

    if (['gender', 'businessType'].includes(fieldName)) {
      return (
        <CustomSelectInputBase
          field={field}
          value={value || val}
          disabled={secured}
          placeholder={placeholder}
          handleChange={onChange || handleChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
          showCheckBox={false}
          error={error || false}
          helperText={error && helperText}
          required={required}
        />
      );
    }
    return (
      <CustomInputBase
        label={label}
        value={value || val}
        size="small"
        type={type || 'text'}
        onWheel={(e) => { e.target.blur(); }}
        error={error || false}
        helperText={error && helperText}
        loading={false}
        required={required}
        disabled={secured}
        name={fieldName}
        onChange={handleChange}
        placeholder={placeholder}
        cSize="lg"
        readOnly={false}
        sAdornment={sAdornment}
      />
    );
  };

  const updateFile = [
    {
      name: 'cacFile', label: 'Corporate Affairs Commission (CAC)',
      fileName: cacFileName, size: cacFileSize, url: cacUrl
    },
    {
      name: 'pharmRegFile', label: 'Pharmacist Council Registration Licence',
      fileName: pcrFileName, size: pcrFileSize, url: pcrUrl
    },
    nafdacUrl && {
      name: 'permitFile', label: 'NAFDAC Import Permit',
      fileName: nafdacFileName, size: nafdacFileSize, url: nafdacUrl
    }
  ].filter(Boolean);

  return (
    <>
      <SubText>Manage your Account</SubText>

      <Grid container spacing={4}>
        <Grid item xs={2}>
          <SettingsPaper elevation={0}>
            <Grid container>
              <Grid item xs={0.8} style={{ background: '#f1f8ff' }}>
                <SideDesign />
              </Grid>
              <Grid item xs={11.2}>
                <SettingsBox>
                  <SettingsIcon />
                  <ProfileText>Profile Settings</ProfileText>
                </SettingsBox>
              </Grid>
            </Grid>
          </SettingsPaper>
        </Grid>

        <Grid item xs={10}>
          <ProfilePaper elevation={0}>
            <HeaderBox>
              <BusinesssName>{name}</BusinesssName>
              <Grid container>
                <Grid item xs={6}>
                  <VerifyBox active={status}>
                    <Bullet active={status} />
                    <VerifiedText>
                      {status === 'ACTIVE' ? 'Account Verified' : 'Account Not Verified'}
                    </VerifiedText>
                  </VerifyBox>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <EditButton
                    startIcon={
                      !editing ? (<Editicon style={{ fill: '#ffffff', fontSize: '1.5rem' }} />)
                        : (<SaveUpdateIcon style={{ fontSize: '1.5rem' }} />)
                      }
                    onClick={handleSaveButtonClick}
                  >
                    {loading ? (
                      <CircularProgressLoader
                        disableShrink
                        size={22}
                        thickness={5}
                      />
                    ) : (
                      <EditButtonText>
                        {!editing ? 'Edit Profile Info' : 'Save Update'}
                      </EditButtonText>
                    )}
                  </EditButton>
                </Grid>
              </Grid>
            </HeaderBox>

            <Grid container spacing={3} style={{ paddingLeft: '1.5rem' }}>
              {formFields.map((field) => (
                <Grid item key={field?.name} xs={6}>{returnTextField(field)}</Grid>
              ))}
            </Grid>
          </ProfilePaper>

          <IdUploads
            businessId={businessId}
            accountType={accountType}
            files={files}
            filesField={updateFile}
          />
        </Grid>
      </Grid>
      <SuccessDialog
        openDialog={openDialog}
        setOpenDialog={() => setOpenDialog(false)}
        title="Details Saved"
        desc="Your details has been updated successfully!"
        option="Ok"
      />
    </>
  );
};

SellerSettings.propTypes = {
  userData: PropTypes.instanceOf(Object).isRequired,
  businessId: PropTypes.number.isRequired,
  accountType: PropTypes.string.isRequired
};

export default SellerSettings;
