import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ShoppingBagIcon,
  FilledShoppingBagIcon,
  PromotionIcon,
  NotificationIcon,
  FilledPromotionIcon,
  FilledNotificationIcon
} from '../../../assets/svgs';
import {
  StaticFooter,
  StaticFooterSection,
  StaticFooterText,
} from './productStaticFooter.styles';

const ProductStaticFooter = ({ path }) => {
  const navigate = useNavigate();
  const [currentTag, setCurrentTag] = useState(path);

  const mobileFooterNavigation = [
    {
      name: 'All Products',
      tag: 'all_products',
      icon: FilledShoppingBagIcon,
      iconAlt: ShoppingBagIcon,
      link: '/new-order',
    },
    {
      name: 'Promo Products',
      tag: 'promo_products',
      icon: FilledPromotionIcon,
      iconAlt: PromotionIcon,
      link: '',
    },
    {
      name: 'Notifications',
      tag: 'notifications',
      icon: FilledNotificationIcon,
      iconAlt: NotificationIcon,
      link: '/notifications/all',
    }
  ];

  const route = (tag, link) => {
    navigate(link);
  };

  return (
    <StaticFooter>
      {mobileFooterNavigation.map((item) => {
        const {
          name, icon: Icon, iconAlt: IconAlt, link, tag
        } = item;
        const isActive = tag === currentTag;
        return (
          <StaticFooterSection
            key={name}
            tag={tag}
            onClick={() => route(tag, link)}
          >
            {
            isActive ? (
              <Icon
                style={{
                  fontSize: '4rem',
                  fill: ''
                }}
              />
            )
              : (
                <IconAlt
                  style={{
                    fontSize: '4.1rem',
                    fill: '#fff'
                  }}
                />
              )
          }
            <StaticFooterText tag={isActive}>{name}</StaticFooterText>
          </StaticFooterSection>
        );
      })}
    </StaticFooter>
  );
};

ProductStaticFooter.propTypes = {
  path: PropTypes.string,
};

ProductStaticFooter.defaultProps = {
  path: 'all_products'
};

export default ProductStaticFooter;
