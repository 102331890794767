import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Close } from '@mui/icons-material';
import {
  DialogActions, DialogContent, DialogTitle,
  Grid,
  Slide,
  useMediaQuery
} from '@mui/material';
import {
  CDialog,
  CircularProgressLoader,
  DialogActionButtonText,
  DialogCancelButton, DialogOkButton,
  DialogTitleSubText,
  DialogTitleText,
  DialogTitleWrapper,
  FormWrapper,
  GridWrapper,
  TopGrid
} from './individualOutletDialog.styles';
import SavePopper from './savePopper';
import CustomInputBase from '../../customComponents/customInputBase';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const IndividualOutletDialog = ({
  dialogOpen, closeDialog, existingOutlet, cart, state, setState
}) => {
  const isSmall = useMediaQuery('(max-width: 991px)');

  const initialState = {
    deliveryState: '',
    landmark: '',
    deliveryAddress: '',
    city: ''
  };
  const [addressState, setAddressState] = useState(initialState);

  useEffect(() => {
    setAddressState((s) => ({
      ...s, deliveryState: state?.deliveryState?.replace(',', ''), landmark: state.landmark, deliveryAddress: state.deliveryAddress, city: state.city
    }));
  }, [state]);

  const {
    deliveryState, city, deliveryAddress, landmark
  } = addressState;

  const initialErrorState = {
    deliveryStateError: false,
    deliveryCityError: false,
    deliveryAddressError: false,
    landmarkError: false,
  };

  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [saveButtonEl, setSaveButtonEl] = useState(null);

  const handleCloseDialog = () => {
    closeDialog();
  };

  const {
    deliveryStateError, deliveryCityError, deliveryAddressError, landmarkError
  } = errorState;

  const handleSave = () => {
    setState((s) => ({
      ...s, deliveryAddress, city, deliveryState, landmark
    }));
    setSaveButtonEl(null);
    handleCloseDialog();
  };

  const handleSaveContinue = async () => {
    handleSave();
  };

  const handleSaveButtonClick = (event) => {
    if (deliveryState === '') {
      toast.error('Delivery state required');
    } else if (city === '') {
      toast.error('Delivery city is required');
    } else if (deliveryAddress === '') {
      toast.error('Delivery address is required');
    } else if (landmark === '') {
      toast.error('Landmark is required');
    } else {
      // setSaveButtonEl(saveButtonEl ? null : event.currentTarget);
      handleSave();
    }
    return setErrorState({
      ...errorState,
      deliveryStateError: deliveryState === '',
      deliveryCityError: city === '',
      deliveryAddressError: deliveryAddress === '',
      landmarkError: landmark === '',
    });
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'deliveryState':
      case 'city':
      case 'deliveryAddress':
      case 'landmark':
        return setErrorState({
          ...errorState,
          [`${name}Error`]: !value.length || value === undefined
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setAddressState({ ...addressState, [name]: value });
  };

  const fields = [
    {
      name: 'deliveryState', label: 'State', secured: !!existingOutlet, show: true, val: deliveryState,
      onChange: handleChange, placeholder: 'River', required: true, error: deliveryStateError, helperText: 'Delivery state required'
    },
    {
      name: 'landmark', label: 'Landmark', secured: false, show: true, val: landmark,
      onChange: handleChange, placeholder: 'Shoprite', required: true, type: 'text', error: landmarkError, helperText: 'Landmark required',
    },
    {
      name: 'deliveryAddress', label: 'Address', secured: false, show: true, val: deliveryAddress,
      onChange: handleChange, placeholder: '29, owodunni street, Off Awoyokun ', required: true, type: 'text', error: deliveryAddressError, helperText: 'Delivery address required',
    },
    {
      name: 'city', label: 'City', secured: false, show: true, val: city,
      onChange: handleChange, placeholder: 'Port-Harcourt', required: true, type: 'text', error: deliveryCityError, helperText: 'Delivery city required'
    }
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, required, error, helperText, secured, placeholder, onChange, val, label, type,
      isTooltip, tooltipDesc
    } = field;
    return (
      <FormWrapper item xs={12} lg={6}>
        <CustomInputBase
          label={label}
          value={val}
          size="small"
          type={type || 'text'}
          onWheel={(e) => { e.target.blur(); }}
          error={error || false}
          helperText={error && helperText}
          loading={false}
          required={required}
          disabled={secured}
          name={fieldName}
          onChange={onChange}
          placeholder={placeholder}
          cSize="lg"
          readOnly={false}
          isTooltip={isTooltip}
          tooltipDesc={tooltipDesc}
        />
      </FormWrapper>
    );
  };

  return (
    <>
      <CDialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        fullWidth
      >
        <Grid container>
          <GridWrapper container item xs>
            <DialogTitle id="alert-dialog-slide-title">
              <Close
                fontSize="large"
                style={{
                  position: 'absolute', right: '1.5rem', cursor: 'pointer',
                  width: isSmall ? '50px' : '25px', height: isSmall && '50px',
                  color: '#235A91'
                }}
                onClick={handleCloseDialog}
              />
              <DialogTitleWrapper container>
                <DialogTitleText>
                  {existingOutlet ? 'Edit Delivery Location' : 'Add Delivery Location' }
                </DialogTitleText>
                <DialogTitleSubText>
                  {existingOutlet
                    ? 'Edit the form to update outlet information'
                    : 'Add your delivery information to the fields in the form'}
                </DialogTitleSubText>
              </DialogTitleWrapper>
            </DialogTitle>
            <DialogContent container sm={12}>
              <TopGrid container item>
                {fields.map((field) => returnTextField(field))}
              </TopGrid>
            </DialogContent>
            <DialogActions style={{ display: isSmall ? 'block' : 'flex' }}>
              <DialogCancelButton
                onClick={handleCloseDialog}
              >
                <DialogActionButtonText>
                  Cancel
                </DialogActionButtonText>
              </DialogCancelButton>

              <DialogOkButton
                onClick={handleSaveButtonClick}
              >
                {loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={22}
                    thickness={5}
                  />
                ) : (
                  <DialogActionButtonText>
                    Submit
                  </DialogActionButtonText>
                )}
              </DialogOkButton>
            </DialogActions>
          </GridWrapper>
        </Grid>
      </CDialog>

      <SavePopper
        saveButtonEl={saveButtonEl}
        setSaveButtonEl={setSaveButtonEl}
        handleSave={handleSave}
        handleSaveContinue={handleSaveContinue}
      />
    </>
  );
};

IndividualOutletDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  existingOutlet: PropTypes.instanceOf(Object),
  cart: PropTypes.bool,
  state: PropTypes.instanceOf(Object).isRequired,
};
IndividualOutletDialog.defaultProps = {
  existingOutlet: {},
  cart: false
};
export default IndividualOutletDialog;
