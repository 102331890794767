import { ArrowBack } from '@mui/icons-material';
import { Typography, Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContentContainer = styled(Typography)`
    padding: 2rem 5rem 4rem 5rem;
    background: #fff;
    @media (max-width: 991px) {
        padding: 2rem 3rem 5rem 3rem;

    }
`;

export const ProductTypo = styled(Typography)`
    font-size: 1.5rem;
    font-weight: 700;
    color: #303030;
    line-height: 40px;

    @media (max-width: 991px) {
        margin-top: 2rem;
        font-size: 3rem;
        font-weight: 650;
    }
`;

export const ProductNameTypo = styled(Typography)`
    font-size: 1.35rem;
    font-weight: 400;
    color: #303030;
    line-height: 25px;
    margin: .6rem 0;

    @media (max-width: 991px) {
        font-size: 2.2rem;
        font-weight: 400;
        margin: 2rem 0;
    }
`;

export const MenuButton = styled(Button)`
    border: none;
    &.MuiButtonBase-root {
      padding: 0px;
    }
    @media (max-width: 991px) {
      padding-bottom: 20px;
      .MuiButton-iconSizeMedium > *:first-child {
        font-size: 60px;
      }
  }
`;

export const BackTypo = styled(Typography)`
    font-weight: 600;

    @media (max-width: 991px) {
        font-size: 2.3rem;
        font-weight: 500;
    }
`;

export const CategoriesBox = styled('div')`

  @media (max-width: 991px) {
  }

`;

export const BackArrowIconContainer = styled(IconButton)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 10px 0px;
  margin: 0;
  color: #235A91;
  font-size: 14px;
  @media(max-width: 991px) {
    padding: 15px 0px;
  }
`;

export const KeyboardArrowLeft = styled(ArrowBack)`
  font-size: 1.2rem;
  @media(max-width: 991px) {
    font-size: 3rem;
    margin-right:  7px;
  }
`;

export const ArrowBackText = styled('p')`
  color: #235A91;
  font-size: 1rem;
  margin: 0;
 
  @media (max-width: 991px) {
    font-weight: 500;
    font-size: 2.5rem;
    padding-top: 0;
  }
`;
