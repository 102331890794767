import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  TableContainer, Table, TableRow, TableBody, TableHead,
  Grid,
  Collapse,
  IconButton,
  Hidden
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  TableBox, MainTableRow, BodyCell, HeaderCell, ProductTypo, Content,
  Label,
  Title,
  ProductInfoContainer,
  Value
} from './productInfo.styles';

export default function ProductInfo({
  product, businessUser
}) {
  const {
    qpc, sku, meta, business, expiryDate, manufacturer, quantityInStock
  } = product;
  const obj = JSON.parse(meta);
  const { pack_size: packSize } = obj;
  const { name } = business;

  const headers = businessUser === 'RETAILER'
    ? ['SKU', 'Pack Size', 'Category', 'UOM', 'Expiry Date']
    : ['SKU', 'Qty/Ctn', 'Pack Size', 'Category', 'UOM', 'Manufacturer', 'Expiry Date'];

  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const mobileView = [
    {
      title: 'SKU',
      value: sku
    },
    {
      title: 'Qty',
      value: quantityInStock || 'N/A'
    },
    {
      title: 'Pack Size',
      value: packSize || 'N/A'
    },
    {
      title: 'UOM',
      value: 'N/A'
    },
    {
      title: 'Manufacturer',
      value: manufacturer || 'N/A'
    },
    {
      title: 'Expiry Date',
      value: expiryDate ? moment(expiryDate).format('MMM DD, YYYY') : 'N/A'
    },
  ];

  return (
    <Content>
      <Hidden mdDown>
      <ProductTypo>Product Information</ProductTypo>
      <TableBox>
        <TableContainer>
          <Table aria-label="payments table">
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <HeaderCell key={header}>{header}</HeaderCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <MainTableRow>
                <BodyCell>
                  {sku}
                </BodyCell>
                {businessUser !== 'RETAILER' && <BodyCell>{qpc || 'N/A'}</BodyCell>}
                <BodyCell>
                  {packSize || 'N/A'}
                </BodyCell>
                <BodyCell>
                  {qpc || 'N/A'}
                </BodyCell>
                <BodyCell>
                  {qpc || 'N/A'}
                </BodyCell>
                {businessUser !== 'RETAILER' && <BodyCell>{name || 'N/A'}</BodyCell>}
                <BodyCell>
                  {expiryDate ? moment(expiryDate).format('MMM DD, YYYY') : 'N/A'}
                </BodyCell>
              </MainTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </TableBox>
      </Hidden>
      <Hidden mdUp>
        <ProductInfoContainer>
          <Grid container alignItems="center" justifyContent="space-between">
            <Title variant="h6">Product Information</Title>
            <IconButton onClick={handleToggle} aria-label="expand">
              {open ? <ExpandLess style={{ color: '#ccc', fontSize: '4rem' }} /> : <ExpandMore style={{ color: '#ccc', fontSize: '4rem' }} />}
            </IconButton>
          </Grid>

          <Collapse in={open}>
            <Grid container spacing={8} sx={{ paddingTop: '2.5rem' }}>
              {
                mobileView.map((item) => (
                  <>
                    <Grid item xs={6}>
                      <Label variant="body1">{item.title}</Label>
                    </Grid>
                    <Grid item xs={6}>
                      <Value variant="body1">{item.value}</Value>
                    </Grid>
                  </>
                ))
              }
            </Grid>
          </Collapse>
        </ProductInfoContainer>
      </Hidden>
    </Content>
  );
}

ProductInfo.propTypes = {
  product: PropTypes.instanceOf(Object),
  businessUser: PropTypes.string
};

ProductInfo.defaultProps = {
  product: {},
  businessUser: ''
};
