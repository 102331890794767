import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { PRODUCT_DETAILS_QUERY } from '../../../../queries/products';
import {
  ContentContainer, ProductTypo, ProductNameTypo,
  BackArrowIconContainer, KeyboardArrowLeft, ArrowBackText
} from './detailsContainer.styles';
import MainContent from '../../../customComponents/mainContent';
import ProductDetail from './productDetail';
import ProductInfo from './productInfo';
import SimilarProducts from '../../../cart/similarProducts/similarProduct';
import NewOrdersLoader from '../../../customComponents/loaders/newOrdersLoader';
import { useStateValue } from '../../../../providers/stateProvider';

const DetailsContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [{
    user: { session },
  }] = Object.values(useStateValue());
  const { businessType } = session || {};

  const {
    loading, error, data
  } = useQuery(PRODUCT_DETAILS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: Number(id),
    },
  });

  if (loading) return <NewOrdersLoader column={1} />;
  if (error) return <div>{error?.message}</div>;

  const { product = {} } = data || {};
  const { brandName } = product;
  return (
    <MainContent>
      <ContentContainer>
        <BackArrowIconContainer item sm={3} md={1} onClick={() => navigate(-1)} className="product-details-back-button">
          <KeyboardArrowLeft />
          <ArrowBackText>Back</ArrowBackText>
        </BackArrowIconContainer>
        <ProductTypo>Product Detail</ProductTypo>
        <ProductNameTypo>
          {brandName}
        </ProductNameTypo>
        <ProductDetail product={product} />
        <ProductInfo product={product} businessUser={businessType} />
        <SimilarProducts searchProductId={id} />
      </ContentContainer>
    </MainContent>
  );
};

export default DetailsContainer;
