import React from 'react';
import {
  useMediaQuery,
  DialogContent,
  Slide,
  Box,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import exclamation from '../../assets/images/Warning.gif';

import {
  DialogContentStyled,
  DescStyled,
  ButtonDarkStyled,
  FooterStyled,
  DialogContainer
} from './successDialog.style';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const PriceDisclaimer = ({
  openDialog, setOpenDialog, placeOrder, loading
}) => {
  const handleClose = async () => {
    setOpenDialog(false);
  };

  const isSmall = useMediaQuery('(max-width: 991px)');

  return (
    <DialogContainer
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <Close
          fontSize="small"
          style={{
            position: 'absolute', right: '1.5rem', cursor: 'pointer', fontSize: isSmall ? '3rem' : ''
          }}
          onClick={handleClose}
        />
        <DialogContent style={{ marginTop: '2rem' }}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Box marginBottom={4}>
              <img src={exclamation} alt="warning" width={isSmall ? 350 : 170} />
            </Box>
            <DescStyled>
              Please note that the order price
              {' '}
              <b>“may Change”</b>
              {' '}
              when you receive your proforma, though it may also remain the same, depending on market conditions.
            </DescStyled>
            <DescStyled style={{ fontWeight: '600' }}>Do you wish to proceed to place order?</DescStyled>
          </Box>
        </DialogContent>
        <FooterStyled>
          <ButtonDarkStyled
            onClick={placeOrder}
            fullWidth
            disabled={loading}
          >
            { loading ? 'loading ...' : 'Proceed' }
          </ButtonDarkStyled>
        </FooterStyled>
      </DialogContentStyled>
    </DialogContainer>
  );
};

PriceDisclaimer.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  placeOrder: PropTypes.func,
};

PriceDisclaimer.defaultProps = {
  openDialog: false,
  setOpenDialog: () => false,
  placeOrder: () => null,
};

export default PriceDisclaimer;
