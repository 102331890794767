import {
  Dialog, Grid, Typography, IconButton, CircularProgress, TextField, Box,
  Button, Checkbox
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    max-height: ${({ addBuyer }) => (addBuyer ? '50rem' : '43rem')};
    width: 30.875rem;
    overflow-y: auto;
    border-radius: 12px;
    box-shadow: 0px 2.96px 66.64px 0px rgba(163, 171, 185, 0.12);
  }
`;

export const GridWrapper = styled(Grid)`
  & > * {
    padding: 0.5rem 1.5rem;
    width: 100%;

    &:first-child {
      padding: 1.5rem;
    }
    &:last-child {
      padding: 2.5rem 1.5rem;
    }
  }
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
`;

export const DialogTitleText = styled(Typography)`
  font-weight: 700;
  color: #303030;
  font-size: 1.25rem;
  margin-bottom: 4px;
`;

export const DialogSubTitleText = styled('span')`
  font-weight: 500;
  color: #303030;
  font-size: 1.25rem;
  margin-bottom: 4px;
  text-transform: capitalize;
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #424242;
  margin-top: 4px;
`;

export const AddCateButton = styled(Button)`
  border-radius: 5px;
  text-align: center;
  font-size: .875rem;
  font-weight: 700;
  border: 1.5px solid #235a91;
  width: 100%;
  height: 2.75rem;
  color: #235a91;
  text-transform: none;
`;

export const PlusSign = styled('span')`
  font-size: 1.5rem;
  font-weight: 500;
`;

export const NameBox = styled(Box)`
  height: 4.125rem;
  border-radius: 12px;
  padding: 1.25rem .875rem;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 0.5px solid rgba(208, 213, 221, 0.2);
  margin-bottom: 1rem;
`;

export const BuyersName = styled(Typography)`
  font-size: 1rem;
  font-weight: 400;
  color: #101828;
`;

export const SearchContainer = styled(Grid)`
  align-items: center;
  margin-bottom: 2rem;
  border: 1px solid #E7E8E9;
  border-radius: 8px;

  & .MuiAutocomplete-popper {
    z-index: 1400;
  };

  @media(min-width: 992px) {
    margin-bottom: 0;
  }
`;

export const SearchTextField = styled(TextField)`
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid rgba(231, 232, 233, 1);

  & ::placeholder {
    font-size: .813rem;
    font-weight: 500;
    color: #858383;
  }
  

  & .MuiOutlinedInput-input {
    padding: 25px;
    @media(max-width: 991px) {
      padding: 30px;
    }
  }

  & .MuiOutlinedInput-root {
    font-size: 2.2rem;
    border-radius: 10px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
  }

  & .MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
  }

  & .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
    border-width: 0;
  }

  @media(min-width: 992px) {
    border: none;

    & .MuiOutlinedInput-input {
      padding: 15px 14px;
    }

    & .MuiOutlinedInput-root {
      font-size: 1rem;
      border-radius: 8px;
      min-height: 2.5rem;
    }

  }
`;

export const SearchButton = styled(IconButton)`
  cursor: pointer;
  padding: 14px 20px;
  color: #424242;
  border-radius: 10px;
  z-index: 2;
  margin-right: -14px;

  &:hover {
    background-color: #D2C647;
  }

  &:disabled {
    color: #424242;
  }

  @media(min-width: 992px) {
    padding: 10px 7px 10px 12px;
  }
`;

export const SearchImage = styled('img')`
  width: 64px;

  @media(min-width: 992px) {
    width: 1.25rem;
  }
`;

export const TitleText = styled(Typography)`
  font-weight: 700;
  color: #303030;
  font-size: 1.125rem;
  margin-bottom: 4px;
`;

export const NameText = styled('span')`
  text-transform: capitalize;
`;

export const SubTitleText = styled(Typography)`
  font-weight: 400;
  color: #606060;
  font-size: .875rem;
  margin-bottom: 4px;
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #78AADA;
  padding: 0 !important;
`;

export const CheckboxImage = styled('img')`
  width: 1.25rem;
`;

export const AddBuyerButton = styled(Button)`
  border-radius: 5px;
  background: #235a91;
  height: 3rem;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  font-size: .875rem;
  padding: 1.75rem;

  &:hover {
    background: #235a91;
    color: #ffffff;
  }
`;
