import React from 'react';
import {
  useMediaQuery,
  DialogContent,
  Slide,
  Box,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import exclamation from '../../../assets/images/List.gif';

import {
  DialogContentStyled,
  DescStyled,
  ButtonDarkStyled,
  FooterStyled,
  DialogContainer,
  TitleStyled
} from './gridNetwork.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const GridNetwork = ({
  openDialog, setOpenDialog, action
}) => {
  const handleClose = async () => {
    setOpenDialog(false);
  };

  const isSmall = useMediaQuery('(max-width: 991px)');

  return (
    <DialogContainer
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <Close
          fontSize="small"
          style={{
            position: 'absolute', right: '1.5rem', top: isSmall ? 40 : 20, cursor: 'pointer', fontSize: isSmall ? '3rem' : ''
          }}
          onClick={handleClose}
        />
        <DialogContent style={{ marginTop: '2rem' }}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Box marginBottom={4} style={{ background: '#F3F9FF', padding: '2rem', borderRadius: '2rem 2rem 0 0' }}>
              <img src={exclamation} alt="warning" width={isSmall ? '40%' : 170} />
            </Box>
            <TitleStyled>Low Data Mode!</TitleStyled>
            <DescStyled>
              Running on a  low speed internet? We now have a list view on our marketplace, that helps you load products faster.
            </DescStyled>
          </Box>
        </DialogContent>
        <FooterStyled>
          <ButtonDarkStyled
            onClick={action}
            fullWidth
          >
            Try it now
          </ButtonDarkStyled>
        </FooterStyled>
      </DialogContentStyled>
    </DialogContainer>
  );
};

GridNetwork.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  action: PropTypes.func,
};

GridNetwork.defaultProps = {
  openDialog: false,
  setOpenDialog: () => false,
  action: () => null,
};

export default GridNetwork;
