import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  DialogActions, Slide, Grid, Box, InputAdornment
} from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import {
  CDialog, DialogTitleText, CircularProgressLoader, SearchContainer, SearchTextField,
  GridWrapper, CloseIcon, DialogSubTitleText, SubTitleText, CustomCheckbox,
  AddCateButton, PlusSign, BuyersName, NameBox, AddBuyerButton,
  SearchButton, SearchImage, CheckboxImage, TitleText,
  NameText,
} from './viewBuyersDialog.styles';
import SuccessDialog from '../indevidualCategory/successDialog';
import SearchIcon from '../../../assets/images/marketOrder/searchIcon.png';
import CheckboxIcon from '../../../assets/images/supplier/Checkbox-selected.png';
import { MANAGE_USERS_ACCOUNT } from '../../../queries/manageAccounts';
import { ADD_CATEGORY_BUSINESS } from '../../../mutations/categories';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
const checkedIcon = <CheckboxImage src={CheckboxIcon} alt="checked" />;

const ViewBuyersDialog = ({
  open, close, category, refetch: allCatRefetch
}) => {
  const initialState = { searchCategory: '' };

  const [state, setState] = useState(initialState);
  const [successDialog, setSuccessDialog] = useState(false);
  const [addBuyer, setAddBuyer] = useState(false);
  const [addCategoryBusiness] = useMutation(ADD_CATEGORY_BUSINESS);
  const [selectedBuyerIds, setSelectedBuyerIds] = useState([]);

  const { searchCategory } = state;
  const { id: catId, name } = category || {};

  const {
    loading, data, refetch
  } = useQuery(MANAGE_USERS_ACCOUNT, {
    skip: !catId || !open,
    fetchPolicy: 'no-cache',
    variables: {
      businessType: 'BUYER',
      categoryId: +catId,
    }
  });

  const {
    loading: addLoading, data: addData
  } = useQuery(MANAGE_USERS_ACCOUNT, {
    skip: !catId || !open || !addBuyer,
    fetchPolicy: 'no-cache',
    variables: {
      businessType: 'BUYER',
      categoryId: +catId,
      excludeCurrentCategoryBusinesses: true,
      name: searchCategory.length > 2 ? searchCategory : ''
    }
  });

  useEffect(() => {
    if (catId && open) {
      refetch();
    }
  }, [catId, refetch, open]);

  const { allBusinesses = [] } = data || {};
  const { allBusinesses: excludedCategories = [] } = addData || {};

  const handleSuccessDialog = () => {
    setSuccessDialog(!successDialog);
  };

  const handleSearch = (value) => {
    setState({ ...state, searchCategory: value });
  };

  const toggleSuccessModal = () => {
    handleSuccessDialog();
    close();
  };

  const handleCloseDialog = () => {
    setState(initialState);
    setAddBuyer(false);
    setSelectedBuyerIds([]);
    close();
  };

  const handleSelectCategory = (id) => {
    setSelectedBuyerIds((prevSelected) => {
      if (prevSelected.includes(+id)) {
        return prevSelected.filter((buyerId) => +buyerId !== +id);
      }
      return [...prevSelected, +id];
    });
  };

  const handleAddBuyer = () => {
    addCategoryBusiness({
      variables: {
        categoryId: +catId,
        businessIds: selectedBuyerIds,
      }
    })
      .then(({ data: resp }) => {
        const { message } = resp?.addBusinessToCategory || {};
        toast.success(message);
        setState(initialState);
        setSuccessDialog(true);
        setSelectedBuyerIds([]);
        setAddBuyer(false);
        allCatRefetch();
        close();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const renderSearchField = () => (
    <SearchContainer>
      <SearchTextField
        variant="outlined"
        value={searchCategory}
        onChange={(e) => handleSearch(e.target.value)}
        placeholder="Search for buyers"
        fullWidth
        className="pmp-uat-search-buyer"
        type="text"
        InputProps={{
          startAdornment:
  <InputAdornment
    position="start"
    style={{ cursor: 'pointer' }}
  >
    <SearchButton
      aria-label="Search Order"
      edge="start"
      disabled
    >
      <SearchImage
        alt="search icon"
        src={SearchIcon}
      />
    </SearchButton>
  </InputAdornment>
        }}
      />
    </SearchContainer>
  );

  return (
    <CDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth
      addBuyer={addBuyer}
    >
      <GridWrapper container>
        <Grid
          container
          style={{
            marginTop: '1rem',
            padding: addBuyer ? '1.5rem 1.5rem 10px' : '1.5rem 1.5rem 1rem'
          }}
        >
          <Grid item xs={10}>
            {!addBuyer ? (
              <DialogTitleText container item xs={12}>
                Buyers
                {' '}
                <DialogSubTitleText>
                  (
                  {name}
                  )
                </DialogSubTitleText>
              </DialogTitleText>
            ) : (
              <>
                <TitleText container item xs={12}>
                  Add Buyers to
                  &#34;
                  <NameText>{name}</NameText>
                  &#34;
                </TitleText>
                <SubTitleText container item xs={12}>
                  Select buyer and add to category
                </SubTitleText>
              </>
            )}
          </Grid>
          <Grid item container xs={2} justifyContent="flex-end">
            <CloseIcon onClick={handleCloseDialog} />
          </Grid>
        </Grid>

        <Box>
          {!addBuyer ? (
            <AddCateButton onClick={() => setAddBuyer(true)}>
              <PlusSign>
                +
              </PlusSign>
              &nbsp;
              Add Buyers to Category
            </AddCateButton>
          ) : (
            renderSearchField()
          )}
        </Box>

        <Box style={{ padding: '0.5rem 1.5rem' }}>
          {!addBuyer ? (
            loading ? (
              <Box style={{ textAlign: 'center', margin: '1.5rem' }}>
                <CircularProgressLoader
                  disableShrink
                  size={32}
                  thickness={5}
                />
              </Box>
            ) : (
              allBusinesses.map(({ name: catName }) => (
                <NameBox key={catName}>
                  <BuyersName>{catName}</BuyersName>
                </NameBox>
              )))
          ) : (
            addLoading ? (
              <Box style={{ textAlign: 'center', margin: '1.5rem' }}>
                <CircularProgressLoader
                  disableShrink
                  size={32}
                  thickness={5}
                />
              </Box>
            ) : (
              excludedCategories.map(({ name: catName, id: cateId }) => (
                <NameBox key={catName} onClick={() => handleSelectCategory(cateId)}>
                  <Grid container>
                    <Grid item xs={1}>
                      <CustomCheckbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selectedBuyerIds.includes(+cateId)}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <BuyersName>{catName}</BuyersName>
                    </Grid>
                  </Grid>
                </NameBox>
              )))
          )}
        </Box>

        {addBuyer && (
          <DialogActions style={{ padding: '0rem 1.5rem 2rem' }}>
            <AddBuyerButton fullWidth onClick={handleAddBuyer}>
              {loading ? (
                <CircularProgressLoader
                  disableShrink
                  size={22}
                  thickness={5}
                />
              ) : (
                'Add Buyers'
              ) }
            </AddBuyerButton>
          </DialogActions>
        )}

      </GridWrapper>
      <SuccessDialog
        openConfirmation={successDialog}
        handleClose={toggleSuccessModal}
        title="Success"
        discreption="Buyer (s) has been successfully Added to this category!"
      />
    </CDialog>
  );
};

ViewBuyersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  category: PropTypes.instanceOf(Object).isRequired,
  refetch: PropTypes.func.isRequired
};

export default ViewBuyersDialog;
