import { gql } from '@apollo/client';

export const MANAGE_USERS_ACCOUNT = gql`
  query getAllBusiness(
    $name: String
    $email: String
    $location: String
    $status: StatusEnum
    $accountType: BusinessTypeEnum
    $dateFrom: String
    $dateTo: String
    $pageCount: Int
    $pageNumber: Int
    $businessType: BusinessEnum!
    $categoryId: Int
    $excludeCurrentCategoryBusinesses: Boolean
  ) {
    allBusinesses(
      name: $name
      email: $email
      location: $location
      status: $status
      accountType: $accountType
      dateFrom: $dateFrom
      dateTo: $dateTo
      pageCount: $pageCount
      pageNumber: $pageNumber
      businessType: $businessType
      categoryId: $categoryId
      excludeCurrentCategoryBusinesses: $excludeCurrentCategoryBusinesses
    ) {
      id
      dateCreated
      name
      status
      location
      businessType
      businessuserSet {
        business {
          name
          buyerMeta {
            verificationStatus
          }
          sellerMeta {
            verificationStatus
          }
        }
      }
    }
    allBusinessesTotalNumber
  }
`;
