import {
  Typography,
  Grid,
  Button,
  TextField,
  Box,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AddFrame, CartBag, MinusFrame } from '../../../../assets/svgs';

export const DetailGrid = styled(Grid)`
  min-height: 430px;
  margin-top: 20px;
  box-shadow: 0px 2.13419px 48.0481px rgba(163, 171, 185, 0.12);
  border-radius: 15px;
  padding: 0px 7rem 0px;

  @media (max-width: 991px) {
    height: 100%;
    border-radius: 15px;
    margin-top: 5rem;
    padding: 0px;
  }
`;

export const DetailContentGrid = styled(Grid)`
  height: 100%;
  width: 100%;
  position: relative;
  @media (max-width: 991px) {
    padding-bottom: 10px;
  }
`;

export const ContentGrid = styled(Grid)`
  height: 100%;
  width: 100%;
`;

export const ImgLogo = styled('img')`
  width: 50%;
  display: block;
  margin: auto;
`;

export const DetailHeader = styled(Typography)`
  color: #787885;
  font-weight: 400;
  font-size: 16px;
  margin-top: 10px;

  @media (max-width: 991px) {
    font-size: 35px;
    font-weight: 400;
  }
`;

export const DetailTypo = styled(Typography)`
  color: #394359;
  font-weight: 700;
  font-size: 20px;

  @media (max-width: 991px) {
    font-size: 37px;
    font-weight: 650;
    margin-bottom: 2rem;
  }
`;

export const QuantityGrid = styled(Grid)`
  margin-top: 5px;
  width: 90%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const QuantityContentGrid = styled(Grid)``;

export const InputButtonGroup = styled(Box)`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-left: -0.5rem;
  
  @media (max-width: 991px) {
    margin-left: 1rem;
  }
`;

export const ActionButton = styled(Button)`
  border: none !important;
  background: none !important;
  box-shadow: none !important;
  width: 100px !important;

  &:disabled {
    border: none !important;
    background: none !important;
  }

  &:hover {
    border: none;
    background: none !important;
  }

  @media (min-width: 992px) {
    &.MuiButtonGroup-grouped {
      min-width: 0;
    }

    &.MuiButtonGroup-groupedOutlinedHorizontal:not(:first-child) {
    }
  }
`;

export const ActionTextField = styled(TextField)`
  & input.MuiInput-input {
    height: 2em;
    font-size: 1.25rem;
    color: #212121;
    font-weight: 600;
    text-align: center;
  }
  & ::placeholder {
    color: #000;
    text-align: center;
  }

  &
    input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputMarginDense.MuiInput-inputMarginDense {
    font-size: 1.2em;
    width: 1.2rem;
    text-align: center;
    color: #000;
  }

  @media (min-width: 992px) {
    margin-left: 10px;
  }

  @media (max-width: 991px) {
    input.MuiInput-input {
      font-size: 2.5rem;
      color: #212121;
      font-weight: 600;
    }
  }
`;

export const ActionImg = styled('img')`
  width: 20px;

  @media (max-width: 991px) {
    width: 100px;
  }
`;

export const Content = styled(Box)`
  padding: 20px;
  position: relative;
`;

export const AddButton = styled(Button)`
  border-radius: 8px !important;
  background-color: #245a91;
  margin-top: 20px;
  color: #fff !important;
  width: 100% !important;
  height: 55px !important;

  &:hover {
    background-color: #245a91;
    color: #fff;
  }

  &.MuiButton-contained.Mui-disabled {
    color: #fff;
    opacity: 0.5;
  }

  @media (min-width: 992px) {
    border-radius: 4px;
  }

  @media (max-width: 991px) {
    height: 120px;
  }
`;

export const AddCartTypo = styled(Typography)`
  font-size: 0.9rem;
  font-weight: 600;

  @media (max-width: 991px) {
    font-size: 2rem;
  }
`;

export const ShoppingCartImg = styled(CartBag)`
  color: #235a91;
  &.MuiSvgIcon-root {
    width: 17px;
    height: 15px;
  }

  @media (max-width: 991px) {
    &.MuiSvgIcon-root {
      width: 35px;
      height: 35px;
    }
  }
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #c5c5c5;
`;

export const Typo = styled(Typography)`
  font-size: 30px;
  font-weight: 650;
  margin-top: 5px;

  @media (max-width: 991px) {
    font-size: 50px;
    font-weight: 700;
  }
`;

export const DivBullet = styled('span')`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #235a91;
`;

const getBGColor = (status) => {
  switch (status) {
    case 72:
      return '#D9FFEC';
    case 'clearanceSale':
      return '#F3F9FF';
    default:
      return '#FAE7C0';
  }
};

const getTextColor = (status) => {
  switch (status) {
    case 72:
      return '#07DE10';
    case 'clearanceSale':
      return '#235A91';
    default:
      return '#E49800';
  }
};

export const ProductVariantText = styled('p')`
  position: absolute;
  right: 30px;
  top: 25px;
  text-align: center;
  font-weight: 500;
  font-weight: 500;
  font-size: 12px;
  padding: 8px 10px;
  color: ${({ status }) => getTextColor(status)};
  background-color: ${({ status }) => getBGColor(status)};

  @media (max-width: 991px) {
    font-size: 1.5rem;
  }
`;

export const PlusIcon = styled(AddFrame)`
  &.MuiSvgIcon-root {
    font-size: 3rem;
  }

  @media (max-width: 991px) {
    &.MuiSvgIcon-root {
      font-size: 6.5rem;
    }
  }
`;

export const MinusIcon = styled(MinusFrame)`
  &.MuiSvgIcon-root {
    font-size: 3rem;
  }

  @media (max-width: 991px) {
    &.MuiSvgIcon-root {
      font-size: 6.5rem;
    }
  }
`;
