import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import currencyFormatter from '../shared/currencyFormatter';
import { JSONParse } from '../../utils/json';
import {
  BodyCell, MainTableRow, ColorGrid, Dot
} from './returnRow.styles';

const ReturnRow = ({
  row, index, tab
}) => {
  const {
    id, total, order: { datePlaced: date }, meta, status
  } = row;
  const { amount_payable, sorted_value } = JSONParse(meta);

  const navigate = useNavigate();
  const datePlaced = moment(date).format('DD/MM/YY (h:mm a)');
  const amountPayable = currencyFormatter(amount_payable);
  const totalOrderValue = currencyFormatter(total);
  const orderId = `#${id}`;

  const getColorGrid = (color, bkg, name) => (
    <ColorGrid item container color={color} background={bkg} className={`mpAdmin-uat-ordersPage-${name}`}>
      <Dot background={bkg} />
      {name}
    </ColorGrid>
  );

  const getStatus = () => {
    switch (status) {
      case 'ORDER_PLACED':
        return getColorGrid('#07DE10', '#00B588', 'Order placed');
      case 'ORDER_DISPATCHED':
        return getColorGrid('#606060', '#F29053', 'Dispatched');
      case 'ORDER_QUEUED_FOR_DISPATCH':
        return getColorGrid('#606060', '#F29053', 'Ready for ...');
      case 'ORDER_REJECTED':
        return getColorGrid('#606060', '#FF0F0F', 'Rejected');
      case 'ORDER_SORTED':
        return getColorGrid('#606060', '#F29053', 'Order Sorted');
      case 'ORDER_SENT':
        return getColorGrid('#606060', '#F29053', 'Order sent');
      case 'ORDER_CLOSED':
        return getColorGrid('#07DE10', '#F29053', 'Completed');
      case 'ORDER_AWAITING_APPROVAL':
        return getColorGrid('#606060', '#F29053', 'Awaiting Approval');
      case 'CLOSED':
        return getColorGrid('#07DE10', '#00B588', 'Completed');
      default:
        return getColorGrid('#606060', '#F29053', 'Order Received');
    }
  };
  const tov = `₦ ${totalOrderValue}`;
  const amount = `₦ ${amountPayable}`;
  const sortedValue = `₦ ${currencyFormatter(sorted_value)}`;

  const erpCells = () => {
    switch (tab) {
      case 'OPEN':
        return [datePlaced, orderId, tov, sortedValue, amount];
      case 'CLOSED':
        return [datePlaced, orderId, sortedValue];
      default:
        return null;
    }
  };

  const handleClick = () => {
    navigate(`/manage-orders/dispatch/${id}`);
  };

  const returnCell = (val) => val;

  const renderBodyCells = () => erpCells().map((cell) => (
    <BodyCell key={cell} onClick={() => handleClick(id)} style={{ cursor: 'pointer' }}>{returnCell(cell)}</BodyCell>
  ));

  return (
    <MainTableRow item container style={index % 2 ? { backgroundColor: '#F3F9FF' } : { backgroundColor: '#FFF' }}>
      {renderBodyCells()}
      <BodyCell>{getStatus()}</BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  index: PropTypes.number.isRequired,
  tab: PropTypes.string.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
