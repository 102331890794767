import React from 'react';
import {
  DialogActions, DialogContent, DialogContentText, Slide, Box
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import success from '../../../assets/images/Checksuccesful.gif';
import {
  ConfirmButton, DialogContainer, SubTitleText, TitleText
} from './successDialog.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const SuccessDialog = ({
  openConfirmation,
  handleClose,
  title,
  discreption
}) => (
  <DialogContainer
    open={openConfirmation}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogContent>
      <Close
        fontSize="medium"
        style={{ position: 'absolute', right: '1.5rem', cursor: 'pointer' }}
        onClick={handleClose}
      />
      <DialogContentText style={{ marginTop: '1rem' }}>
        <Box paddingY={5} sx={{ width: '100%', textAlign: 'center' }}>
          <Box marginBottom={4}>
            <img src={success} alt="success" width={155} />
          </Box>
          <TitleText>
            { title || 'Role Changed Succcesfully!' }
          </TitleText>
          <SubTitleText>
            { discreption || 'Hi Pharm, user has been successfully Changed' }
          </SubTitleText>
        </Box>
      </DialogContentText>
      <DialogActions style={{ marginBottom: '2rem' }}>
        <ConfirmButton fullWidth onClick={handleClose}>
          Ok
        </ConfirmButton>
      </DialogActions>
    </DialogContent>
  </DialogContainer>
);

SuccessDialog.propTypes = {
  openConfirmation: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  discreption: PropTypes.string
};

SuccessDialog.defaultProps = {
  title: '',
  discreption: ''
};

export default SuccessDialog;
