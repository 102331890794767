import {
  Grid, Typography, IconButton, Button, MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Button)`
  padding: 5px 0px;
  width: 25rem;
  align-items: center;
  cursor: pointer;

  @media (min-width: 992px) {
    padding: 0px;
    width: 250px;
  }

  &:hover {
    background-color: #ecf6ff;
  }
`;

export const GridWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

export const ItemTypo = styled(Typography)`
  margin-left: 15px;
  font-size: 2.2rem;

  @media (min-width: 992px) {
    margin-left: 0px;
    font-size: 1rem;
    padding: 6px 12px;
  }
`;

export const ItemWrapper = styled(IconButton)`
  border-radius: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const MenuItemStyled = styled(MenuItem)`
  font-size: 15px;
  font-weight: 400;
  padding: 0.6rem 1.7rem;

  @media (max-width: 991px) {
    font-size: 2.2rem;
    font-weight: 400;
    padding: 2rem 3rem;
  }
`;
