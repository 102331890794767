import {
  Popper, Paper, Grid, CircularProgress, DialogContentText, Dialog
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const PopperContainer = styled(Popper)`
  z-index: 15000000;
  @media(max-width: 991px) {
    left: -155px !important;
  }
`;

export const WrapperPaper = styled(Paper)`
  z-index: 1500
`;

export const CustomDialog = styled(Dialog)`
  @media(max-width: 991px) {
    .MuiDialog-paperWidthSm {
      min-width: 930px;
    }
  }
`;
export const CDialog = styled('p')`
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0;

  @media(max-width: 991px) {
    font-size: 3rem;
  }
`;

export const TopGrid = styled(Grid)`
    & > * {
      width: calc(50% - 0.75rem);
      margin: 0 1.5rem 1.5rem 0;

      :nth-child(2n) {
        margin-right: 0;
      }
    }
`;

export const GridItem = styled('div')`
    width: 100%;
    display: flex;
    margin-top: 2rem;
    align-items: center;
    justify-content: space-between;
`;

export const GridTitle = styled(Grid)`
  margin-top: 0.3rem;
`;

export const Item = styled(Paper)`
  background-color: #EDF6FF;
  width: 52px;
  height: 43px;
  align-items: center;
  justify-content: center;
  display: flex;

`;
export const CircularProgressLoader = styled(CircularProgress)`
  color: #424242;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  position: relative;
`;

export const DialogText = styled(DialogContentText)`
  font-size: 12px;
  font-weight: 400;
  @media(max-width: 991px) {
    font-size: 2.2rem;
  }
`;
