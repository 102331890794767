import React, {
  useState, useLayoutEffect, useEffect, useRef
} from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  InputAdornment, Hidden, useMediaQuery,
} from '@mui/material';
import { GridView, List, Window } from '@mui/icons-material';
import NewOrder from './newOrder';
import MainContent from '../../customComponents/mainContent';
import SearchIcon from '../../../assets/images/marketOrder/searchIcon.png';
import {
  OrderGridContainer, OrderSubHeader, SearchContainer, BoxGrid,
  ClearIcon, SearchImage, SearchTextField, SearchButton,
  ProductsBox, SearchBox, ContentBox, ProductSide, Header,
  FilterDropGrid, FilterDropButton, TitleHeader,
  FilterSide, CheckboxImage, MobileSearchButton, MobileSearchText,
  StyledToggleButtonGroup,
  StyledToggleButton,
  SwitchContainer,
  SwichStep
} from './marketOrderContainer.styles';
import { useStateValue } from '../../../providers/stateProvider';
import AffiliateActionTypes from '../../../providers/reducers/affiliate/affiliateTypes';
import CartActionTypes from '../../../providers/reducers/cart/cartTypes';
import useSearchParamsState from '../../shared/helpers/ulrSearchParams';
import CheckboxIcon from '../../../assets/images/supplier/Checkbox-selected.png';
import ProductSearchPopper from './productSearchPopper';
import { NEW_ORDER_PRODUCTS_SEARCH_QUERY } from '../../../queries/products';
import ActionPopper from './actionPopper';
import OrderFilter from './filters/orderFilter';
import { GridListToggle } from '../../../assets/svgs';
import GridNetwork from './gridNetwork';
import { convertToCapitalization } from '../../shared/methods';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckboxImage src={CheckboxIcon} alt="checked" />;

const MarketOrderContainer = ({
  location
}) => {
  const { pathname } = location;
  const pageCount = sessionStorage.getItem('rowsPerPage') || 20;
  const initialState = {
    searchText: '',
    searchValue: '',
    currentPage: 1,
    currentPageCount: pageCount,
    priceRange: '',
    productVariant: ''
  };

  const isSmall = useMediaQuery('(max-width: 991px)');
  const [state, setState] = useState(initialState);
  const [filters, setFilters] = useSearchParamsState('filters', '');
  const vals = filters.split(',');
  const [searchFieldEl, setSearchFieldEl] = useState(null);
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [savedEl, setSavedEl] = useState(null);
  const textFieldRef = useRef(null);
  const searchContainerRef = useRef(null);
  const [filterButtonEl, setFilterButtonEl] = useState(null);

  const [{
    user: { session: { businessType } }
  }, dispatch] = Object.values(useStateValue());

  const [openDialog, setOpenDialog] = useState(true);

  const isRetailer = businessType === 'RETAILER';
  const {
    priceRange, searchValue, productVariant
  } = state;

  useEffect(() => {
    setState((s) => ({
      ...s, searchValue: vals[0] || '', priceRange: vals[1] || '', productVariant: vals[2] || ''
    }));

    if (textFieldRef.current && document.activeElement === textFieldRef.current && searchFieldValue === '' && vals[0].length) {
      const values = `${''},${priceRange},${productVariant}`;
      setFilters(values);
      setSearchedProducts([]);
      setSearchFieldEl(null);
      setSavedEl(null);
    }

    if (vals[0].length && searchFieldValue.length < 1 && document.activeElement !== textFieldRef.current) {
      setSearchFieldValue(vals[0]);
    }
  }, [filters, searchFieldValue, productVariant]);
  // filters, vals, searchFieldValue, productVariant

  const variables = {
    search: '',
    marketProducts: true,
    productName: searchFieldValue.length > 2 ? searchFieldValue : '',
    pageCount: 10,
    pageNumber: 1,
    ...(productVariant && { productVariant }),
  };

  const { loading: searchLoading, data: searchData } = useQuery(NEW_ORDER_PRODUCTS_SEARCH_QUERY, {
    fetchPolicy: 'no-cache',
    variables,
  });

  useEffect(() => {
    if (searchData && searchData.products) {
      setSearchFieldEl(savedEl);
      setSearchedProducts(searchData?.products);
    }
  }, [searchData]);

  const handlePopperClose = () => {
    setSearchFieldEl(null);
    setSavedEl(null);
  };

  useLayoutEffect(() => {
    dispatch({
      type: AffiliateActionTypes.UPDATE_AFFILIATE,
      payload: {
        addAffiliateDialogOpen: true
      }
    });
    dispatch({
      type: CartActionTypes.UPDATE_IS_MANUAL,
      payload: pathname?.startsWith('/manual-order')
    });
  }, [searchFieldValue]);

  const handleSearch = (event, text) => {
    setSearchFieldValue(text);
    if (text.length > 2) {
      setSavedEl(event.target);
      setSearchFieldEl(event.target);
    } else {
      setSearchedProducts([]);
      setSearchFieldEl(null);
      setSavedEl(null);
    }
  };

  const handleProdSelection = (name) => {
    const values = `${name},${priceRange},${productVariant}`;
    setFilters(values);
    setSearchFieldValue(name);
    setSearchedProducts([]);
    handlePopperClose();
  };

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const handleClearSearch = () => {
    const values = `${''},${priceRange},${productVariant}`;
    setFilters(values);
    setSearchFieldValue('');
  };

  const handleFilterButtonClick = (event) => {
    if (event?.currentTarget === filterButtonEl) return;
    setFilterButtonEl(filterButtonEl ? null : event.currentTarget);
  };

  const handleAction = (e, type) => {
    const filterVal = `${searchValue},${type},${productVariant}`;
    setFilters(filterVal);
    setFilterButtonEl(null);
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      const values = `${searchFieldValue},${priceRange},${productVariant}`;
      setFilters(values);
      handlePopperClose();
    }
  };

  const handleMobileSearch = () => {
    const values = `${searchFieldValue},${priceRange},${productVariant}`;
    setFilters(values);
    handlePopperClose();
  };

  const renderSearchField = (
    placeholder,
    searchVal,
    handleChange,
    ClearSearch,
    handleKeyPress,
    small = false,
    joyClass = 'joy-search-product'
  ) => (
    <SearchContainer className={joyClass} ref={searchContainerRef}>
      <SearchTextField
        variant="outlined"
        inputRef={textFieldRef}
        label=""
        value={searchVal}
        onChange={(e) => handleChange(e, e.target.value)}
        onKeyDown={handleKeyPress}
        placeholder={placeholder}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ marginRight: '25px' }}>
              <SearchButton
                aria-label={placeholder}
                edge="start"
                disabled={!searchValue}
                small={small}
                onClick={ClearSearch}
              >
                {searchValue ? (
                  <ClearIcon small={small} />
                ) : (
                  <SearchImage alt="search icon" src={SearchIcon} small={small} />
                )}
              </SearchButton>
            </InputAdornment>
          ),
          endAdornment: isSmall ? (
            <InputAdornment position="end" style={{ marginRight: '25px' }}>
              <MobileSearchButton onClick={handleMobileSearch}>
                <MobileSearchText>
                  Search
                </MobileSearchText>
              </MobileSearchButton>
            </InputAdornment>
          ) : null,
        }}
      />
    </SearchContainer>
  );

  const handleClearFilter = () => {
    const values = `${searchValue},${priceRange},${''}`;
    setFilters(values);
    setState(initialState);
  };

  const handleProductsAvailability = (variant) => {
    const values = `${searchValue},${priceRange},${productVariant === variant ? '' : variant}`;
    setFilters(values);
    setState({ ...state, productVariant: productVariant === variant ? '' : variant });
  };

  const [view, setView] = useState('grid');

  const handleListView = () => {
    setView('list');
    setOpenDialog(false);
  };

  const handleChange = (event, newView) => {
    setView(newView);
  };

  return (
    <MainContent>
      <ContentBox>
        <OrderGridContainer container>
          <BoxGrid container>
            {isRetailer && (
              <FilterSide>
                <Hidden smDown>
                  <OrderFilter
                    checkedIcon={checkedIcon}
                    icon={icon}
                    priceRange={[]}
                    containerState={state}
                    handleClearFilter={handleClearFilter}
                    handleProductsAvailability={handleProductsAvailability}
                  />
                </Hidden>
              </FilterSide>
            )}
            <ProductSide retailer={isRetailer}>
              <ProductsBox>
                <Header container xs={12}>
                  <Hidden mdDown>
                    <TitleHeader>
                      All Products
                    </TitleHeader>
                  </Hidden>
                  <OrderSubHeader xs={12} md={6} container spacing={2}>
                    <SearchBox item xs={10} md={8}>
                      <Hidden mdUp>
                        {renderSearchField('Search for Products', searchFieldValue, handleSearch, handleClearSearch, handleEnterPress, false, 'joy-search-product')}
                      </Hidden>
                      <Hidden mdDown>
                        {renderSearchField('Search for Products', searchFieldValue, handleSearch, handleClearSearch, handleEnterPress)}
                      </Hidden>
                    </SearchBox>
                    <FilterDropGrid item xs={2} md={4}>
                      <FilterDropButton
                        variant="outlined"
                        onClick={handleFilterButtonClick}
                        className="mpFe-uat-orders-create-filter-button"
                      >
                        <GridListToggle style={{ fontSize: isSmall ? '2.5rem' : '1.2rem', marginRight: isSmall ? '' : '7px' }} />
                        {isSmall ? '' : 'Filter By Price'}
                      </FilterDropButton>
                    </FilterDropGrid>
                  </OrderSubHeader>

                  <Hidden mdUp>
                    <TitleHeader>All Products</TitleHeader>
                    <SwitchContainer>
                      <SwichStep>{convertToCapitalization(view)}</SwichStep>
                      <StyledToggleButtonGroup
                        value={view}
                        exclusive
                        onChange={handleChange}
                        aria-label="view toggle"
                      >
                        <StyledToggleButton value="grid" aria-label="grid view">
                          <Window className="icon" />
                        </StyledToggleButton>
                        <StyledToggleButton value="list" aria-label="list view">
                          <List className="icon" />
                        </StyledToggleButton>
                      </StyledToggleButtonGroup>
                    </SwitchContainer>
                  </Hidden>
                </Header>
                <NewOrder
                  state={state}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  view={view}
                />
                <ProductSearchPopper
                  searchFieldEl={searchFieldEl}
                  handleClose={handlePopperClose}
                  searchedProducts={searchedProducts}
                  handleProdSelection={handleProdSelection}
                  loading={searchLoading}
                  width={searchContainerRef.current ? searchContainerRef.current.offsetWidth : null}
                />
              </ProductsBox>
            </ProductSide>
          </BoxGrid>
        </OrderGridContainer>
      </ContentBox>
      <ActionPopper
        action={filterButtonEl}
        handleAction={handleAction}
        handleClose={handleFilterButtonClick}
      />
      <Hidden mdUp>
        <GridNetwork openDialog={openDialog} setOpenDialog={setOpenDialog} action={handleListView} />
      </Hidden>
    </MainContent>
  );
};

MarketOrderContainer.propTypes = {
  location: PropTypes.instanceOf(Object),
};

MarketOrderContainer.defaultProps = {
  location: {},
};

export default MarketOrderContainer;
