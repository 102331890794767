import React from 'react';
import { DialogContent, Slide } from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import {
  DialogContentStyled, TextFieldStyled, DialogContainer,
  TextLabel, DescStyled, ButtonDarkStyled
} from './buyerMarkup.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const BuyerMarkup = ({
  openDialog, setOpenDialog, markup, setMarkup, confirmDialogHandler,
  location
}) => {
  const handleClose = () => {
    setOpenDialog();
  };

  return (
    <DialogContainer
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <Close
          fontSize="small"
          style={{
            position: 'absolute',
            right: '1.5rem',
            cursor: 'pointer',
            top: '1rem'
          }}
          onClick={handleClose}
        />
        <DialogContent style={{ marginTop: '2rem' }}>
          <DescStyled>
            Input Markup perentage
          </DescStyled>

          <TextLabel>Location</TextLabel>
          <TextFieldStyled
            value={location}
            disabled
          />

          <TextLabel>Percentage</TextLabel>
          <TextFieldStyled
            value={markup}
            type="number"
            onChange={(e) => {
              setMarkup(e.target.value);
            }}
            placeholder="%"
          />
          <ButtonDarkStyled disabled={!markup?.length} onClick={confirmDialogHandler} fullWidth>
            Update Markup
          </ButtonDarkStyled>
        </DialogContent>
      </DialogContentStyled>
    </DialogContainer>
  );
};

BuyerMarkup.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  markup: PropTypes.string,
  confirmDialogHandler: PropTypes.func.isRequired,
  setMarkup: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
};

BuyerMarkup.defaultProps = {
  openDialog: false,
  setOpenDialog: () => false,
  markup: '',
};

export default BuyerMarkup;
